














































import {VueEx} from '@/common/core/VueEx';
import {Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class ReservationUserDetail extends VueEx {
  @Prop({ default: 0 }) readonly userId!: string;
  private user = {
    id: 0,
    name: '',
    member_id: 0,
    tel: '',
    email: '',
    birthday: '',
    status: 0,
    grade: 0,
    admin_flg: false,
    gender: 0,
    genre: '',
    memo: '',
    payjp_customer_id: '',
    created_at: '',
    updated_at: '',
  };
    snackbar: { color: string, text: string, display: boolean, mode: string, x: string, y: string, timeout: number } = {
    color: '',
    text: '',
    display: false,
    mode: 'multi-line',
    x: 'right',
    y: 'top',
    timeout: 6000,
  };

  get planText() {
    return this.config.user.grade[this.user.grade];
  }

  private copyToClipboard(text: string, url: string) {
    navigator.clipboard.writeText(text).then(() => {
      this.showToast('顧客IDがクリップボードにコピーされました');
      setTimeout(() => {
        window.open(url, '_blank'); // 1秒後に新しいタブでURLを開く
      }, 1000); // 遅延時間を1000ミリ秒（1秒）に設定
    }).catch(err => {
      this.showToast('クリップボードへのコピーに失敗しました', 'error');
      console.error('クリップボードへのコピーに失敗しました', err);
    });
  }

  private showToast(message: string, color = 'success') {
    this.snackbar.text = message;
    this.snackbar.color = color; // デフォルトは'success'、必要に応じて変更可能
    this.snackbar.display = true;
  }

  private getUser(userId: string) {
    this.clientAdapter.getUser<{ id: number, name: string, tel: string, status: number, grade: number, admin_flg: boolean, email: string, birthday: string, gender: number, genre: string, memo: string,  payjp_customer_id: string, created_at: string, updated_at: string}>(userId).then((res) => {
      this.user = {
        id: res.id,
        name: res.name,
        member_id: res.id + 89,
        tel: res.tel,
        email: res.email,
        birthday: res.birthday,
        status: res.status,
        grade: res.grade,
        admin_flg: res.admin_flg,
        gender: res.gender,
        genre: res.genre,
        memo: res.memo,
        payjp_customer_id: res.payjp_customer_id,
        created_at: res.created_at,
        updated_at: res.updated_at,
      };
    })
  }

  @Watch('userId')
  onUserIdChanged(newUserId: string, oldUserId: string) {
    if (newUserId !== oldUserId) {
      this.getUser(newUserId);
    }
  }
}
