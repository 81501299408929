
























































































































































































































































































































































































import {VueEx} from '@/common/core/VueEx';
import {Component} from 'vue-property-decorator';

@Component
export default class NewRoom extends VueEx {
  private name: string = '';
  private area: string = '';
  private capacity: string = '';
  private dayNormalPrice: string = '';
  private dayCreditPrice: string = '';
  private dayEarlyPrice: string = '';
  private midNormalPrice: string = '';
  private midCreditPrice: string = '';
  private midEarlyPrice: string = '';
  private storeId: string = '';
  private material: string = '';
  private vertical: string = '';
  private width: string = '';
  private reader: FileReader = new FileReader();
  private blob!: any;
  private image!: File;
  private items: { title: string, placeholder: string, hint: string, rules: any }[] = [
    {
      title: 'Name',
      placeholder: '',
      hint: '必須 最大文字数: 255 半角英数字のみ',
      rules: {
        required: (value: string) => !!value || '必須 最大文字数: 255',
        counter: (value: string) => value.length <= 255 || '必須 最大文字数: 255',
        name: (value: string) => {
          const pattern = /^[0-9a-zA-Z]*$/;
          return pattern.test(value) || '半角英数字で入力してください';
        }
      },
    },
    {
      title: 'Area',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        area: (value: string) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || '半角数字で入力してください';
        }
      },
    },
    {
      title: 'Capacity',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        capacity: (value: string) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || '半角数字で入力してください';
        }
      },
    },
    {
      title: 'DayNormalPrice',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        dayNormalPrice: (value: string) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || '半角数字で入力してください';
        }
      },
    },
    {
      title: 'DayCreditPrice',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        dayCreditPrice: (value: string) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || '半角数字で入力してください';
        }
      },
    },
    {
      title: 'DayEarlyPrice',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        dayEarlyPrice: (value: string) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || '半角数字で入力してください';
        }
      },
    },
    {
      title: 'MidNormalPrice',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        midNormalPrice: (value: string) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || '半角数字で入力してください';
        }
      },
    },
    {
      title: 'MidCreditPrice',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        midCreditPrice: (value: string) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || '半角数字で入力してください';
        }
      },
    },
    {
      title: 'MidEarlyPrice',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        midEarlyPrice: (value: string) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || '半角数字で入力してください';
        }
      },
    },
    {
      title: 'StoreId',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        storeId: (value: string) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || '半角数字で入力してください';
        }
      },
    },
    {
      title: 'Material',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須 最大文字数: 255',
        counter: (value: string) => value.length <= 255 || '必須 最大文字数: 255',
        material: (value: string) => {
          const pattern = /^[0-9a-zA-Z]*$/;
          return pattern.test(value) || '半角英数字で入力してください';
        }
      },
    },
    {
      title: 'Vertical',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        vertical: (value: string) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || '半角数字で入力してください';
        }
      },
    },
    {
      title: 'Width',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        width: (value: string) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || '半角数字で入力してください';
        }
      },
    },
    {
      title: 'Image',
      placeholder: '',
      hint: '必須。スタジオ検索画面で表示されるスタジオ画像。<注意>予約詳細入力画面で表示する画像はRoomImageタブで設定してください。',
      rules: {
        required: (value: string) => !!value || '必須. スタジオ検索画面で表示されるスタジオ画像 <注意>予約詳細入力画面で表示する画像はRoomImageタブで設定してください。',
      }
    }
  ];
  private snackbar: { color: string, text: string, display: boolean, mode: string, x: string, y: string, timeout: number } = {
    color: '',
    text: '',
    display: false,
    mode: 'multi-line',
    x: 'right',
    y: 'top',
    timeout: 6000,
  };

  /**
   * 部屋を登録する
   * @private
   */
  private createRoom() {
    this.clientAdapter.createRoom(this.name, Number(this.area), Number(this.capacity), Number(this.dayNormalPrice), Number(this.dayCreditPrice), Number(this.dayEarlyPrice), Number(this.midNormalPrice), Number(this.midCreditPrice), Number(this.midEarlyPrice), Number(this.storeId), this.material, Number(this.vertical), Number(this.width), this.blob).then(() => {
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'success';
      this.snackbar.text = '部屋の登録に成功しました。';
      // 項目クリア
    }).catch(() => {
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'error';
      this.snackbar.text = '部屋の登録に失敗しました。しばらく経ってから再度お試しください。';
    });
  }

  /**
   * 画像を登録する
   * @private
   */
  private onImageChange() {
    this.reader.readAsDataURL(this.image);
    this.reader.onload = (e) => {
      // @ts-ignore
      this.blob = e.target.result;
    };
  }
}
