





















































import {Component} from 'vue-property-decorator';
import {VueEx} from '@/common/core/VueEx';

@Component({
  components: {},
})
export default class Login extends VueEx {

  private mailAddress: string = '';
  private password: string = '';
  private msg: string = '';
  private show1: boolean = false;
  private loginForm: boolean = false;
  private rules: { required: any } = {
    required: (value: string) => !!value || 'Required.',
  };

  get isValid() {
    return !this.loginForm;
  }

  /**
   * ログインを行う。
   *
   */
  private loginSystem() {
    this.msg = '';
    if (!this.mailAddress || !this.password) {
      this.msg = 'メールアドレスまたはパスワードが入力されていません。';
      return;
    }
    this.clientAdapter.loginSystem(this.mailAddress, this.password).then((res: { status: string }) => {
      if (res.status === 'aleardy logined') {
        this.$router.push('/home');
        return;
      }
      // ログインが完了したら、ログインセッションを確立する。
      this.clientAdapter.getCurrentAdminUserInfo().then((res: { memberId: string, userName: string, status: number }) => {
        // ユーザ情報をクライアントのセッション情報として保持
        VueEx.memberId = res.memberId;
        VueEx.userName = res.userName;
        VueEx.status = res.status;
        if (this.$route.query.redirect) {
          // @ts-ignore
          this.$router.push((this.$route.query.redirect));
        } else {
          this.$router.push('/home');
        }
      }).catch((err) => {
        console.log(err);
        if(err.status === 403) {
          this.clientAdapter.logoutSystem()
        }
        this.msg = err.data.message;
      });
    }).catch((err) => {
      this.msg = err.data.message;
    });
  }

}
