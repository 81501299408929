
























import {VueEx} from '../../../common/core/VueEx';
import {Component} from 'vue-property-decorator';

@Component({})
export default class UserDetail extends VueEx {
  private columns: string[] = [
    'Name',
    'Member ID',
    'Tel',
    'Status',
    'Grade',
    'Admin Flg',
    'Email',
    'Birthday',
    'Gender',
    'Genre',
    'LINE ID',
    'Payjp Customer ID',
    'Memo',
    'Created At',
    'Updated At',
  ];
  private detail: string[] = [];

  created() {
    this.clientAdapter.getUserByEmail(this.$route.query.email as string).then((res: { id: number, name: string, member_id: number, tel: string, status: number, grade: number, admin_flg: boolean, email: string, birthday: string, gender: number, genre: string, /** 本当は取りたいけど取れない last_sign_in_at: string, failed_attempts: number, */ line_id: string, payjp_customer_id: string, created_at: string, updated_at: string, memo: string }[]) => {
      this.detail = [
        res[0].name,
        res[0].id + 89,
        res[0].tel,
        this.config.user.status[res[0].status],
        this.config.user.grade[res[0].grade],
        res[0].admin_flg,
        res[0].email,
        res[0].birthday,
        this.config.user.gender[res[0].gender],
        res[0].genre,
        // res[0].last_sign_in_at,
        // res[0].failed_attempts,
        res[0].line_id,
        res[0].payjp_customer_id,
        res[0].memo,
        new Date(res[0].created_at).toLocaleString(),
        new Date(res[0].updated_at).toLocaleString(),
      ];
    });
  }
}
