























































import {VueEx} from '@/common/core/VueEx';
import {Component} from 'vue-property-decorator';
import AttendanceTable from '@/views/attendance/AttendanceTable.vue';
import ExportAttendance from '@/views/attendance/ExportAttendance.vue';
import NewAttendance from '@/views/attendance/NewAttendance.vue';

@Component({
  components: {
    AttendanceTable,
    NewAttendance,
    ExportAttendance,
  },
})
export default class Attendance extends VueEx {
  private rsv_items: { text: string, disabled: boolean, href: string }[] = [
    {
      text: 'ダッシュボード',
      disabled: false,
      href: '/',
    },
    {
      text: 'Attendance',
      disabled: true,
      href: '/attendance',
    },
  ];
  private tab: number = 0;
  private items: { page: string, icon: string }[] = [
    {
      page: '一覧',
      icon: 'format-list-bulleted-square',
    },
    {
      page: '新規作成',
      icon: 'plus-thick',
    },
    // {
    //     page: 'エクスポート',
    //     icon: 'export',
    // },
  ];
}
