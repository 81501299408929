




















































import {VueEx} from "@/common/core/VueEx";
import {Component} from "vue-property-decorator";

@Component({})
export default class DeleteAttendance extends VueEx {
  private id: any = this.$route.query.id;

  private snackbar: { color: string, text: string, display: boolean, mode: string, x: string, y: string, timeout: number } = {
    color: '',
    text: '',
    display: false,
    mode: 'multi-line',
    x: 'right',
    y: 'top',
    timeout: 6000,
  };

  private deleteAttendance() {
    this.clientAdapter.deleteAttendance(Number(this.id)).then(() => {
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'success';
      this.snackbar.text = 'Attendanceの削除に成功しました。';
      // 項目クリア
    }).catch(() => {
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'error';
      this.snackbar.text = 'Attendanceの削除に失敗しました。しばらく経ってから再度お試しください。';
    });
  }
}
