








































































































































































































































import {VueEx} from '@/common/core/VueEx';
import {Component} from 'vue-property-decorator';
import ExportOption from '@/views/option/ExportOption.vue';

@Component({
  components: {ExportOption}
})
export default class OptionTable extends VueEx {
  private headers: { text: string, value: string, filter: any, items: string[], conditionWords: string, show: boolean, width: number, sortable: any, selectedCondition: number }[] = [
    {
      text: 'Actions',
      value: 'actions',
      filter: null,
      items: [],
      conditionWords: '',
      show: false,
      width: 100,
      sortable: false,
      selectedCondition: 0,
    },
    {
      text: 'Id',
      value: 'id',
      filter: (value: any) => {
        if (!this.headers[1].conditionWords || !this.headers[1].show) {
          return true;
        }
        return value.toString().includes(this.headers[1].conditionWords);
      },
      items: ['を含む'],
      conditionWords: '',
      show: false,
      width: 70,
      sortable: true,
      selectedCondition: 0,
    },
    {
      text: 'Store',
      value: 'storeName',
      filter: (value: any) => {
        if (!this.headers[2].conditionWords || !this.headers[2].show) {
          return true;
        }
        return value.toString().includes(this.headers[2].conditionWords);
      },
      items: ['を含む'],
      conditionWords: '',
      show: false,
      width: 150,
      sortable: true,
      selectedCondition: 0,
    },
    {
      text: 'Category',
      value: 'categoryName',
      filter: (value: any) => {
        if (!this.headers[3].conditionWords || !this.headers[3].show) {
          return true;
        }
        return value.toString().includes(this.headers[3].conditionWords);
      },
      items: ['を含む'],
      conditionWords: '',
      show: false,
      width: 160,
      sortable: true,
      selectedCondition: 0,
    },
    {
      text: 'Name',
      value: 'name',
      filter: (value: any) => {
        if (!this.headers[4].conditionWords || !this.headers[4].show) {
          return true;
        }
        if (this.headers[4].selectedCondition === 0) {
          return value.toString().includes(this.headers[4].conditionWords);
        } else if (this.headers[4].selectedCondition === 1) {
          return value.toString() === this.headers[4].conditionWords;
        } else if (this.headers[4].selectedCondition === 4) {
          return value.toString().startsWith(this.headers[4].conditionWords);
        } else if (this.headers[4].selectedCondition === 4) {
          return value.toString().endsWith(this.headers[4].conditionWords);
        }
      },
      items: ['を含む', 'に完全に一致', 'で始まる', 'で終わる'],
      conditionWords: '',
      show: false,
      width: 200,
      sortable: true,
      selectedCondition: 0,
    },
    {
     text: 'Price(¥)',
     value: 'price',
     filter: (value: string) => {
        if (!this.headers[5].show || !this.headers[5].conditionWords) {
          return true;
        }
        if (this.headers[5].selectedCondition === 0) {
          return Number(value.replace(/,/g, '')) >= Number(this.headers[5].conditionWords);
        } else if (this.headers[5].selectedCondition === 1) {
          return Number(value.replace(/,/g, '')) <= Number(this.headers[5].conditionWords);
        }
      },
      items: ['以上', '以下'],
      conditionWords: '',
      show: false,
      width: 100,
      sortable: true,
      selectedCondition: 0,
    },
    {
      text: 'Unit',
      value: 'unit',
      filter: (value: number) => {
        if (!this.headers[6].show || !this.headers[6].conditionWords) {
          return true;
        }
        if (this.headers[6].selectedCondition === 0) {
          return value >= Number(this.headers[6].conditionWords);
        } else if (this.headers[6].selectedCondition === 1) {
          return value <= Number(this.headers[6].conditionWords);
        }
      },
      items: ['以上', '以下'],
      conditionWords: '',
      show: false,
      width: 100,
      sortable: true,
      selectedCondition: 0,
    },
    {
      text: 'Description',
      value: 'description',
      filter: (value: any) => {
        if (!this.headers[7].conditionWords || !this.headers[7].show) {
          return true;
        }
        if (this.headers[7].selectedCondition === 0) {
          return value.toString().includes(this.headers[7].conditionWords);
        } else if (this.headers[7].selectedCondition === 1) {
          return value.toString() === this.headers[7].conditionWords;
        } else if (this.headers[7].selectedCondition === 2) {
          return value.toString().startsWith(this.headers[7].conditionWords);
        } else if (this.headers[7].selectedCondition === 3) {
          return value.toString().endsWith(this.headers[7].conditionWords);
        }
      },
      items: ['を含む', 'に完全に一致', 'で始まる', 'で終わる'],
      conditionWords: '',
      show: false,
      width: 160,
      sortable: true,
      selectedCondition: 0,
    },
    {
      text: 'Max Rental Amount',
      value: 'max_rental_amount',
      filter: (value: number) => {
        if (!this.headers[8].show || !this.headers[8].conditionWords) {
          return true;
        }
        if (this.headers[8].selectedCondition === 0) {
          return value >= Number(this.headers[8].conditionWords);
        } else if (this.headers[8].selectedCondition === 1) {
          return value <= Number(this.headers[8].conditionWords);
        }
      },
      items: ['以上', '以下'],
      conditionWords: '',
      show: false,
      width: 160,
      sortable: true,
      selectedCondition: 0,
    },
    {
      text: 'URL',
      value: 'image',
      filter: (value: string) => {
        if (!this.headers[9].show || !this.headers[9].conditionWords) {
          return true;
        }
        if (this.headers[9].selectedCondition === 0) {
          return Number(value.replace(/,/g, '')) >= Number(this.headers[9].conditionWords);
        } else if (this.headers[9].selectedCondition === 1) {
          return Number(value.replace(/,/g, '')) <= Number(this.headers[9].conditionWords);
        }
      },
      items: ['を含む', 'に完全に一致', 'で始まる', 'で終わる'],
      conditionWords: '',
      show: false,
      width: 130,
      sortable: true,
      selectedCondition: 0,
    },
    {
      text: 'Memo',
      value: 'memo',
      filter: (value: any) => {
        if (!this.headers[10].conditionWords || !this.headers[10].show) {
          return true;
        }
        if (this.headers[10].selectedCondition === 0) {
          return value.toString().includes(this.headers[10].conditionWords);
        } else if (this.headers[10].selectedCondition === 1) {
          return value.toString() === this.headers[10].conditionWords;
        } else if (this.headers[10].selectedCondition === 2) {
          return value.toString().startsWith(this.headers[10].conditionWords);
        } else if (this.headers[10].selectedCondition === 3) {
          return value.toString().endsWith(this.headers[10].conditionWords);
        }
      },
      items: ['を含む', 'に完全に一致', 'で始まる', 'で終わる'],
      conditionWords: '',
      show: false,
      width: 200,
      sortable: true,
      selectedCondition: 0,
    },
    {
      text: 'Created at',
      value: 'created_at',
      filter: (value: string) => {
        if (!this.headers[11].show || !this.headers[11].conditionWords) {
          return true;
        }
        if (this.headers[11].selectedCondition === 0) {
          return value.substring(0,10) === this.headers[11].conditionWords;
        }
        else if (this.headers[10].selectedCondition === 1) {
          return value.substring(0,6) === this.headers[11].conditionWords;
        }
        else if (this.headers[11].selectedCondition === 2) {
          let date: string[] = this.headers[11].conditionWords.split('-');
          return new Date(value) <= new Date(Number(date[0]), Number(date[1]) - 1, Number(date[2]), 23, 59, 59);
        }
        else if (this.headers[11].selectedCondition === 3) {
          let date: string[] = this.headers[11].conditionWords.split('-');
          return new Date(value) >= new Date(Number(date[0]), Number(date[1]) - 1, Number(date[2]), 23, 59, 59);
        }
      },
      items: ['に日付が一致 (YYYY-MM-DD)', 'に月が一致 (YYYY-MM)', '以前 (YYYY-MM-DD)', '以降 (YYYY-MM-DD)'],
      conditionWords: '',
      show: false,
      width: 250,
      sortable: true,
      selectedCondition: 0,
    },
    {
      text: 'Updated at',
      value: 'updated_at',
      filter: (value: string) => {
        if (!this.headers[12].show || !this.headers[12].conditionWords) {
          return true;
        }
        if (this.headers[12].selectedCondition === 0) {
          return value.substring(0,9) === this.headers[12].conditionWords;
        }
        else if (this.headers[12].selectedCondition === 1) {
          return value.substring(0,6) === this.headers[12].conditionWords;
        }
        else if (this.headers[12].selectedCondition === 2) {
          let date: string[] = this.headers[12].conditionWords.split('-');
          return new Date(value) <= new Date(Number(date[0]), Number(date[1]) - 1, Number(date[2]), 23, 59, 59);
        }
        else if (this.headers[12].selectedCondition === 3) {
          let date: string[] = this.headers[12].conditionWords.split('-');
          return new Date(value) >= new Date(Number(date[0]), Number(date[1]) - 1, Number(date[2]), 23, 59, 59);
        }
      },
      items: ['に日付が一致 (YYYY-MM-DD)', 'に月が一致 (YYYY-MM)', '以前 (YYYY-MM-DD)', '以降 (YYYY-MM-DD)'],
      conditionWords: '',
      show: false,
      width: 250,
      sortable: true,
      selectedCondition: 0,
    },

  ];
  private chosenColumnOptions: string[] = [
    'エクスポートする',
  ];
  private selected: [] = [];
  private options: { id: number, storeName: string, option_id: number, name: string, price: string, unit: number, description: string, max_rental_amount: number, image: string, memo: string, created_at: string, updated_at: string, category_id: number, categoryName: string}[] = [];
  private dialogFlg: boolean = false;
  private selectedImage: string = '';
  private search: string = '';
  private exportDialogFlg: boolean = false;
  private partialExportDialogFlg: boolean = false;
  private snackbar: { color: string, text: string, display: boolean, mode: string, x: string, y: string, timeout: number } = {
    color: '',
    text: '',
    display: false,
    mode: 'multi-line',
    x: 'right',
    y: 'top',
    timeout: 6000,
  };

  /**
   * 検索条件の文字列を返却する。
   */
  get showDefault() {
    return function(selectedCondition: number, index: number) {
      //@ts-ignore
      return this.headers[index].items[selectedCondition];
    };
  }

created() {
  this.clientAdapter.getAllOptions().then((res: { id: number, option_id: number, name: string, price: number, unit: number, description: string, max_rental_amount: number, image: string, memo: string,  created_at: string, updated_at: string, category_id: number }[]) => {
    this.options = res.map(option => ({
      id: option.id,
      storeName: '',
      option_id: option.option_id,
      category_id: option.category_id,
      name: option.name,
      price: option.price.toString(),
      unit: option.unit,
      description: option.description,
      max_rental_amount: option.max_rental_amount,
      image: option.image,
      memo: option.memo,
      created_at: new Date(option.created_at).toLocaleString(),
      updated_at: new Date(option.updated_at).toLocaleString(),
      categoryName: this.getCategoryName(option.category_id)
    }));

    this.options.forEach(option => {
      if(option.option_id !== null){
          this.clientAdapter.getStoreOptionRefsByOptionId(option.option_id).then((res: { id: number, store_id: number, option_id: number }[]) => {
          if (res.length === 1) {
            const store_id = res[0].store_id;
            option.storeName = this.getStoreName(store_id);
          } else if (res.length > 1) {
            const storeArray: string[] = res.map(store => this.getStoreName(store.store_id));
            option.storeName = storeArray.join(', ');
          }
        });
      } else {
        option.storeName = '未選択'
      }
    });
    this.$forceUpdate();
  });
}

  /**
   * 検索条件を追加する
   * @param index
   * @private
   */
  private addCondition(index: number) {
    if (index === 0) {
      return;
    }
    this.headers[index].show = true;
  }

  /**
   * 検索条件を削除する
   * @param index
   * @private
   */
  private removeCondition(index: number) {
    this.headers[index].show = false;
    this.headers[index].conditionWords = '';
  }

  /**
   * 選択項目を...のリストを押下したときの処理
   * @param index
   * @private
   */
  private processChosenData(index: number) {
    if (this.selected.length === 0) {
      this.snackbar.display = true;
      this.snackbar.color = 'error';
      this.snackbar.text = '対象データを選択してください';
      return;
    }
    if (index === 0) {
      this.partialExportDialogFlg = true;
    }
  }

  /**
   * データエクスポートダイアログを表示する
   */
  private showExportDialog() {
    this.exportDialogFlg = true;
  }

  /**
   * 詳細ベージに遷移する
   * @param id
   * @private
   */
  private dataInfo(id: number) {
    //@ts-ignore
    this.$router.push({path: '/individualOption', query: {id: id, tab: 0}});
  }

  /**
   * データ編集ページに遷移する
   * @param id
   * @private
   */
  private editData(id: number) {
    //@ts-ignore
    this.$router.push({path: '/individualOption', query: {id: id, tab: 1}});
  }

  /**
   * データ削除ページに遷移する
   * @param id
   * @private
   */
  private deleteData(id: number) {
    //@ts-ignore
    this.$router.push({path: '/individualOption', query: {id: id, tab: 2}});
  }

  private selectImage(image: string) {
    this.selectedImage = image;
  }

  /**
   * フィルター条件を変更する
   * @param index
   * @param i
   * @private
   */
  private changeFilterCondition(index: number, i: number) {
    this.headers[index].selectedCondition = i;
  }

  // カテゴリーIDからカテゴリー名に変更
  private getCategoryName(categoryId: number): string {
    const categoryMap: { [key: number]: string } = {
      1: '照明機材',
      2: '音響機材',
      3: 'カメラ',
      4: 'レンズ',
      5: '三脚',
      6: 'カメラアクセサリー',
      7: '撮影関連機材',
      8: 'その他'
    };
    if(categoryId === 99){
      return '削除済み'
    }
    return categoryMap[categoryId] || '非表示中';
  }
     // 店舗IDから店舗名に変更するメソッド
  private getStoreName(storeId: number): string {
     const storeMap: { [key: number]: string } = {
      1: '西早稲田',
      2: '幡ヶ谷',
      3: '渋谷',
      4: '目白',
    };
    if (storeId === 99) {
      return '削除済み';
    }
    return storeMap[storeId] || '非表示中';
  }
}
