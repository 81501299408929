




































































import OptionDetail from './individual/OptionDetail.vue';
import EditOption from './individual/EditOption.vue';
import {VueEx} from '@/common/core/VueEx';
import {Component} from 'vue-property-decorator';
import DeleteOption from './individual/DeleteOption.vue';

@Component({
  components: {
    OptionDetail,
    EditOption,
    DeleteOption,
  }
})
export default class IndividualOption extends VueEx {
  private rsv_items: { text: string, disabled: boolean, href: string }[] = [
    {
      text: 'ダッシュボード',
      disabled: false,
      href: '/',
    },
    {
      text: 'Option',
      disabled: false,
      href: '/option',
    },
    {
      text: '',
      disabled: true,
      href: '/individualOption',
    },
  ];
  private tab: number = Number(this.$route.query.tab);
  private items: { page: string, icon: string }[] = [
    {
      page: '詳細',
      icon: 'information',
    },
    {
      page: '編集',
      icon: 'pencil',
    },
    {
      page: '削除',
      icon: 'close-thick',
    },
  ];
  private id: any = this.$route.query.id;

}
