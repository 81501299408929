
























































































































































































































































import {VueEx} from '@/common/core/VueEx';
import {Component} from 'vue-property-decorator';

@Component
export default class NewOption extends VueEx {
  private id: number = 0;
  private option_id: number = 0;
  private name: string = '';
  private price: number = 0;
  private unit: string = '';
  private description: string = '';
  private max_rental_amount: number = 0;
  private url: string = '';
  private memo: string = '';
  private selectedStore: string = '';
  private selectedCategory: string = '';
  private stores: string[] = ['西早稲田', '幡ヶ谷', '渋谷', '目白'];
  private categoryOptions: string [] = ['照明機材', '撮影関連機材', '音響', 'カメラ', 'レンズ', '三脚','カメラアクセサリー', 'その他'];
  private items: { title: string, placeholder: string, hint: string, rules: any }[] = [
  {
      title: 'Name',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須 最大文字数: 255',
      },
    },
    {
      title: 'Store',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
      },
    },
    {
      title: 'Category',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
      },
    },
     {
      title: 'Price',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        regex: (value: string) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || '半角数字で入力してください';
        }
      },
    },
    {
      title: 'Unit',
      placeholder: '',
      hint: '',
      rules: {},
    },
    {
      title: 'Description',
      placeholder: '',
      hint: '',
      rules: {},
    },
    {
      title: 'MaxRentalAmount',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        regex: (value: string) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || '半角数字で入力してください';
        }
      },
    },
     {
      title: 'Image',
      placeholder: '',
      hint: '',
      rules: {},
    },
     {
      title: 'Memo',
      placeholder: '',
      hint: '',
      rules: {},
    }
  ];
  private snackbar: { color: string, text: string, display: boolean, mode: string, x: string, y: string, timeout: number } = {
    color: '',
    text: '',
    display: false,
    mode: 'multi-line',
    x: 'right',
    y: 'top',
    timeout: 6000,
  };

  /**
   * 部屋を登録する
   * @private
   */
  private createOption() {
    const storeId = this.stores.findIndex(store => store === this.selectedStore) + 1
    const category_id = this.categoryOptions.findIndex(category => category === this.selectedCategory) + 1
    this.clientAdapter.createOption(this.name, Number(this.price), Number(this.unit), this.description, Number(this.max_rental_amount), this.url, this.memo, Number(category_id)).then((res : {id: number}) => {
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'success';
      this.snackbar.text = 'オプションの登録に成功しました。';
      this.clientAdapter.createStoreOptionRef(storeId, res.id)
      // 項目クリア
    }).catch(() => {
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'error';
      this.snackbar.text = 'オプションの登録に失敗しました。しばらく経ってから再度お試しください。';
    });
  }
}
