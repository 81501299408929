


































































import AttendanceDetail from './individual/AttendanceDetail.vue';
import EditAttendance from './individual/EditAttendance.vue';
import DeleteAttendance from './individual/DeleteAttendance.vue';
import {VueEx} from "@/common/core/VueEx";
import {Component} from "vue-property-decorator";

@Component({
  components: {
    AttendanceDetail,
    EditAttendance,
    DeleteAttendance,
  },
})
export default class IndividualAttendance extends VueEx {

  private rsv_items: { text: string, disabled: boolean, href: string }[] = [
    {
      text: 'ダッシュボード',
      disabled: false,
      href: '/',
    },
    {
      text: 'Attendance',
      disabled: false,
      href: '/attendance',
    },
    {
      text: 'Attendance #',
      disabled: true,
      href: '/individualAttendance',
    },
  ];
  private tab: number = Number(this.$route.query.tab);
  private items: { page: string, icon: string, show: boolean }[] = [
    {
      page: '詳細',
      icon: 'information',
      show: true,
    },
    {
      page: '編集',
      icon: 'pencil',
      show: true,
    },
    {
      page: '削除',
      icon: 'close-thick',
      show: true,
    },
  ];
  id: any = this.$route.query.id;
}
