




































































import {VueEx} from '@/common/core/VueEx';
import {Component} from 'vue-property-decorator';
import EditCoupon from '@/views/coupon/individual/EditCoupon.vue';
import CouponDetail from '@/views/coupon/individual/CouponDetail.vue';

@Component({
  components: {
    CouponDetail,
    EditCoupon,
    // DeleteCoupon,
  }
})
export default class IndividualCoupon extends VueEx {
  private rsv_items: { text: string, disabled: boolean, href: string }[] = [
    {
      text: 'ダッシュボード',
      disabled: false,
      href: '/',
    },
    {
      text: 'Coupon',
      disabled: false,
      href: '/coupon',
    },
    {
      text: '',
      disabled: true,
      href: '/individualCoupon',
    },
  ];
  private tab: number = Number(this.$route.query.tab);
  private items: { page: string, icon: string }[] = [
    {
      page: '詳細',
      icon: 'information',
    },
    {
      page: '編集',
      icon: 'pencil',
    },
    // {
    //   page: '削除',
    //   icon: 'close-thick',
    // },
  ];
  private id: any = this.$route.query.id;

}
