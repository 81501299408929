




































































































































































































// @ts-ignore
import {Datetime} from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import {VueEx} from '@/common/core/VueEx';
import {Component} from 'vue-property-decorator';

@Component({
  components: {
    datetime: Datetime
  }
})
export default class NewAttendance extends VueEx {
  private inTime: string = '';
  private outTime: string = '';
  private userId: string = '';

  private items: { title: string, placeholder: string, hint: string, rules: any }[] = [
    {
      title: 'InTime',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
      }
    },
    {
      title: 'OutTime',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
      }
    },
    {
      title: 'UserId',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        format: (value: string) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || '半角数字で入力してください';
        }
      },
    },
    {
      title: 'StoreId',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
      },
    }
  ];
  private snackbar: { color: string, text: string, display: boolean, mode: string, x: string, y: string, timeout: number } = {
    color: '',
    text: '',
    display: false,
    mode: 'multi-line',
    x: 'right',
    y: 'top',
    timeout: 6000,
  };
  private stores: { id: number, store_name: string, address: string, closed_flg: boolean, created_at: string, updated_at: string, onsite_pay_available: boolean, equipment_rentalable: boolean }[] = [];
  private storeId: number = 0;

  created() {
    this.clientAdapter.getStoreInfo().then((res: { id: number, store_name: string, address: string, closed_flg: boolean, created_at: string, updated_at: string, onsite_pay_available: boolean, equipment_rentalable: boolean }[]) => {
      res.forEach(store => {
        this.stores.push({
          id: store.id,
          store_name: store.store_name,
          address: store.address,
          closed_flg: store.closed_flg,
          created_at: store.created_at,
          updated_at: store.updated_at,
          onsite_pay_available: store.onsite_pay_available,
          equipment_rentalable: store.equipment_rentalable,
        });
      })
    });
  }

  private save() {
    this.clientAdapter.createAttendance(this.inTime, this.outTime, Number(this.userId), this.storeId).then(() => {
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'success';
      this.snackbar.text = 'Attendanceの登録に成功しました。';
      // 項目クリア
    }).catch(() => {
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'error';
      this.snackbar.text = 'Attendanceの登録に失敗しました。しばらく経ってから再度お試しください。';
    });
  }
}
