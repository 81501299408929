
























import {VueEx} from '../../../common/core/VueEx';
import {Component} from 'vue-property-decorator';

@Component({})
export default class ReservationDetail extends VueEx {
  private columns: string[] = [
    'CreateTime',
    'ReserveStartTime',
    'ReserveEndTime',
    'MidFlg',
    'EarlyFlg',
    'HowToPay',
    'Billing',
    'StudioBilling',
    'OptionBilling',
    'CommercialUseFlg',
    // 'PaymentFlg',
    'CancelDate',
    'CancelType',
    'CouponCode',
    'memberId',
    'UserName',
    'RoomName',
    'highLevelUsage',
    'detailUsage',
    'numberOfUsers',
    'ReserveStatus',
    'memo',
    'memoForAdmin',
    'options',
    'cancelReason',
    'payjpBillingId',
    'isPaymentSkipped',
    'Created At',
    'Updated At'
  ];
  private detail: string[] = [];

  created() {
    this.clientAdapter.getReservation(Number(this.$route.query.id))
        .then((res: {
          id: number,
          createTime: string,
          reserveStartTime: string,
          reserveEndTime: string,
          midFlg: boolean,
          earlyFlg: boolean,
          howToPay: number,
          billing: number,
          studioBilling: number,
          optionBilling: number,
          isCommercialUse: boolean,
          // paymentFlg: boolean,
          cancelDate: string,
          cancelType: number,
          couponCode: string,
          userName: string,
          memberId: number,
          roomName: string,
          created_at: string,
          updated_at: string,
          highLevelUsage: string,
          detailUsage: string,
          numberOfUsers: number,
          reserveStatus: number,
          memo: string,
          memoForAdmin: string,
          cancelReason: string,
          pay_jp_billing_id: string,
          is_payment_skipped: boolean,
        }) => {
          this.detail = [
            new Date(res.createTime).toLocaleString(),
            new Date(res.reserveStartTime).toLocaleString(),
            new Date(res.reserveEndTime).toLocaleString(),
            res.midFlg,
            res.earlyFlg,
            this.config.reservation.howToPay[res.howToPay],
            '¥ ' + res.billing.toLocaleString(),
            '¥ ' + res.studioBilling.toLocaleString(),
            '¥ ' + res.optionBilling.toLocaleString(),
            res.isCommercialUse,
            // res.paymentFlg,
            new Date(res.cancelDate).toLocaleString() === '1970/1/1 9:00:00' ? '-' : new Date(res.cancelDate).toLocaleString(),
            this.config.reservation.cancelType[res.cancelType],
            res.couponCode,
            res.memberId,
            res.userName,
            res.roomName,
            res.highLevelUsage,
            res.detailUsage,
            res.numberOfUsers,
            this.config.reservation.reserveStatus[res.reserveStatus],
            res.memo,
            res.memoForAdmin,
            "",
            res.cancelReason,
            res.pay_jp_billing_id,
            res.is_payment_skipped,
            new Date(res.created_at).toLocaleString(),
            new Date(res.updated_at).toLocaleString()
          ];
        });
  }
  mounted(){
     this.clientAdapter.getReservationOption(Number(this.$route.query.id))
      .then(async (res: { reservation_options: { id: number, reservation_id: number, option_id: number, amount: number, created_at: string, updated_at: string, optionBilling: number }[] }) => {
        const optionDetailsArray = res.reservation_options.map(async (option) => {
          const option_id = option.option_id;
          const optionData = await this.clientAdapter.getOptionById(option_id); // オプションデータを取得
          const title = optionData.name;
          const amount = option.amount
          return {
            title: title,
            amount: amount,
          };
        });
        const optionDetails: { title: string; amount: number; }[] = [];
        for (const optionDetailPromise of optionDetailsArray) {
          const optionDetail = await optionDetailPromise;
          optionDetails.push(optionDetail);
        }
        this.detail[22] = optionDetails.map(option => `${option.title} × ${option.amount}`).join(', ');        
        this.$forceUpdate(); 
      })
  }
}
