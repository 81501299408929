











































































































































































































































import {VueEx} from '@/common/core/VueEx';
import {Component} from 'vue-property-decorator';

@Component({})
export default class EditOption extends VueEx {
  private id: number = 0;
  private option_id: number = 0;
  private name: string = '';
  private price: number = 0;
  private unit: number = 0;
  private description: string = '';
  private max_rental_amount: number = 0;
  private image: string = '';
  private memo: string = '';
  private selectedCategory: string = '';
  private stores: string[] = ['西早稲田', '幡ヶ谷', '渋谷', '目白']
  private selectedStore: string = '';
  private categoryOptions: string [] = ['照明機材', '音響機材', 'カメラ', 'レンズ', '三脚', 'カメラアクセサリー','撮影関連機材', 'その他'];
  private reader: FileReader = new FileReader();
  private isValidOptionForm: boolean = false;
  private items: { title: string, placeholder: string, hint: string, rules: any }[] = [
    {
      title: 'Name',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須 最大文字数: 255',
      },
    },
    {
      title: 'Store',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
      },
    },
    {
      title: 'Category',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
      },
    },

     {
      title: 'Price',
      placeholder: '',
      hint: '必須',
      rules: {
        regex: (value: string) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || '半角数字で入力してください';
        }
      },
    },
    {
      title: 'Unit',
      placeholder: '',
      hint: '',
      rules: {},
    },
    {
      title: 'Description',
      placeholder: '',
      hint: '',
      rules: {},
    },
    {
      title: 'MaxRentalAmount',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        regex: (value: string) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || '半角数字で入力してください';
        }
      },
    },
     {
      title: 'Image',
      placeholder: '',
      hint: '',
      rules: {},
    },
     {
      title: 'Memo',
      placeholder: '',
      hint: '',
      rules: {},
    },
  ];
  private snackbar: { color: string, text: string, display: boolean, mode: string, x: string, y: string, timeout: number } = {
    color: '',
    text: '',
    display: false,
    mode: 'multi-line',
    x: 'right',
    y: 'top',
    timeout: 6000,
  };

  created() {
    this.clientAdapter.getOptionById(Number(this.$route.query.id)).then((res: { id: number, option_id: number, name: string, price: number, unit: number, description: string, max_rental_amount: number, image: string, memo: string,  created_at: string, updated_at: string, category_id: number }) => {
      this.id = res.id
      if(res.option_id !== null){
        this.option_id = res.option_id;
      } else {
         this.option_id = res.id;
      }
      this.name = res.name
      this.price = res.price;
      this.unit = res.unit;
      this.description = res.description;
      this.max_rental_amount = res.max_rental_amount;
      this.image = res.image;
      this.memo = res.memo;
      this.selectedCategory = this.categoryOptions[res.category_id -1];
    });
   this.clientAdapter.getStoreOptionRefsByOptionId(Number(this.$route.query.id)).then((res: { id: number, store_id: number, option_id: number }[]) => {
    if (res.length === 1) {
      const store_id = res[0].store_id;
      this.selectedStore = this.getStoreName(store_id);
      this.$forceUpdate();
    } 
    else if (res.length > 1){
      const storeArray: string[] = [];
      res.forEach((store) =>{
        const storeName =  this.getStoreName(store.store_id);
        storeArray.push(storeName);
      });
      this.selectedStore = storeArray.join(', ');
      this.$forceUpdate();
    }
  });
  }

  /**
   * 部屋を更新する
   * @private
   */
  private updateOption() {
    if (!this.isValidOptionForm) {
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'error';
      this.snackbar.text = '入力内容に誤りがあります。再度内容をご確認の上、保存してください。';
      return;
    }
    const category_id = this.categoryOptions.findIndex(category => category === this.selectedCategory) + 1
    this.clientAdapter.updateOption(this.id, this.option_id, this.name, this.price, this.unit, this.description, this.max_rental_amount, this.image, this.memo, category_id).then(() => {
      const selectedStoreIndex = this.stores.indexOf(this.selectedStore);
      this.clientAdapter.updateStoreOptionRef(selectedStoreIndex + 1, this.option_id)
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'success';
      this.snackbar.text = 'オプションの登録に成功しました。';
      // 項目クリア
    }).catch(() => {
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'error';
      this.snackbar.text = 'オプションの登録に失敗しました。しばらく経ってから再度お試しください。';
    });
  }
     // 店舗IDから店舗名に変更するメソッド
  private getStoreName(storeId: number): string {
     const storeMap: { [key: number]: string } = {
      1: '西早稲田',
      2: '幡ヶ谷',
      3: '渋谷',
      4: '目白',
    };
    if (storeId === 99) {
      return '削除済み';
    }
    return storeMap[storeId] || '非表示中';
  }
}
