























































import {VueEx} from '@/common/core/VueEx';
import {Component, Prop} from 'vue-property-decorator';

@Component({})
export default class ExportReservation extends VueEx {
  private allFieldSelected: boolean = false;
  private columnList: { name: string, isTarget: boolean }[] = [
    {
      name: 'id',
      isTarget: false,
    },
    {
      name: 'createTime',
      isTarget: false,
    },
    {
      name: 'reserveStartTime',
      isTarget: false,
    },
    {
      name: 'reserveEndTime',
      isTarget: false,
    },
    {
      name: 'midFlg',
      isTarget: false,
    },
    {
      name: 'earlyFlg',
      isTarget: false,
    },
    {
      name: 'howToPay',
      isTarget: false,
    },
    {
      name: 'billing',
      isTarget: false,
    },
    {
      name: 'studioBilling',
      isTarget: false,
    },
    {
      name: 'optionBilling',
      isTarget: false,
    },
    {
      name: 'commercialUse',
      isTarget: false,
    },
    {
      name: 'paymentFlg',
      isTarget: false,
    },
    {
      name: 'cancelDate',
      isTarget: false,
    },
    {
      name: 'cancelType',
      isTarget: false,
    },
    {
      name: 'couponCode',
      isTarget: false,
    },
    {
      name: 'userName',
      isTarget: false,
    },
    {
      name: 'memberId',
      isTarget: false,
    },
    {
      name: 'roomName',
      isTarget: false,
    },
    {
      name: 'reserveStatus',
      isTarget: false,
    },
    {
      name: 'highLevelUsage',
      isTarget: false,
    },
    {
      name: 'detailUsage',
      isTarget: false,
    },
    {
      name: 'created_at',
      isTarget: false,
    },
    {
      name: 'updated_at',
      isTarget: false,
    },
    {
      name: 'memo',
      isTarget: false,
    },
    {
      name: 'memoForAdmin',
      isTarget: false,
    },
  ];

  @Prop({default: []})
  private targets!: { id: number, createTime: string, reserveStartTime: string, reserveEndTime: string, midFlg: boolean, earlyFlg: boolean, howToPay: string, billing: string, studioBilling: string, optionBilling: string, commercialUseFlg: boolean, paymentFlg: boolean, cancelDate: string, cancelType: string, couponCode: string, userName: string, memberId: number, roomName: string, reserveStatus: number, highLevelUsage: string, detailUsage: string, created_at: string, updated_at: string, memo: string, memoForAdmin: string }[];


  /**
   * 全てのカラムを選択する
   * @private
   */
  private selectAllFields() {
    this.columnList.forEach(column => {
      column.isTarget = this.allFieldSelected;
    });
  }

  /**
   * データをcsvに出力する
   * @private
   */
  private exportAsCSV() {
    let targetColumnList: string[] = [];
    let csvContent: string = '';
    // 選択したカラムを抽出する
    this.columnList.forEach(column => {
      if (column.isTarget) {
        targetColumnList.push(column.name);
      }
    });
    // 選択したカラムをCSVのカラムにする
    for (let i = 0; i < targetColumnList.length; i++) {
      csvContent += targetColumnList[i];
      if (i !== targetColumnList.length - 1) {
        csvContent += ',';
      }
    }
    csvContent += '\n';
    // 選択したカラムの値を渡されたオブジェクトから抽出する
    this.targets.forEach(target => {
      let targetValueList: string[] = [];
      targetColumnList.forEach(targetColumn => {
        //@ts-ignore
        targetValueList.push(target[targetColumn]);
      });
      for (let j = 0; j < targetValueList.length; j++) {
        csvContent += targetValueList[j];
        if (j !== targetValueList.length - 1) {
          csvContent += ',';
        }
      }
      csvContent += '\n';
    });
    let now = new Date();
    let blob = new Blob([csvContent], {type: 'text/csv'});
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'reservations_' + now.toLocaleDateString() + '_' + now.toLocaleTimeString() + '.csv';
    link.click();
  }
}
