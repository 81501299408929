

























































import NewRoomImage from './roomImage/NewRoomImage.vue';
import RoomImages from './roomImage/RoomImages.vue';
import {VueEx} from "@/common/core/VueEx";
import {Component} from "vue-property-decorator";

@Component({
  components: {
    NewRoomImage,
    RoomImages,
  },
})
export default class RoomImage extends VueEx {
  private rsv_items: {text: string, disabled: boolean, href: string}[] = [
    {
      text: 'ダッシュボード',
      disabled: false,
      href: '/',
    },
    {
      text: 'RoomImage',
      disabled: true,
      href: '/room_image',
    },
  ]
  private tab: number = 0;
  private items: {page: string, icon: string}[] = [
    {
      page: '一覧',
      icon: 'format-list-bulleted-square',
    },
    {
      page: '新規作成',
      icon: 'plus-thick',
    },
    {
      page: 'エクスポート',
      icon: 'export',
    },
  ]

}
