







































































































// @ts-ignore
import {VueEx} from '@/common/core/VueEx';
import {Component} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ChargeListCsvDownload extends VueEx {

  // @ts-ignore
  private searchCondition: { chargeDateFrom: string, chargeDateTo: string } = {}

  private snackbar: { color: string, text: string, display: boolean, mode: string, x: string, y: string, timeout: number } = {
    color: '',
    text: '',
    display: false,
    mode: 'multi-line',
    x: 'right',
    y: 'top',
    timeout: 6000,
  };

  /**
   * データをcsvに出力する
   * @private
   */
  private exportAsCSV() {
    if (!this.searchCondition.chargeDateFrom || !this.searchCondition.chargeDateTo) {
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'error';
      this.snackbar.text = 'ChargeDate(From)とChargeDate(To)のどちらかが入力されていません。両方入力した上で再度ダウンロードボタンを押してください。';
      return;
    }
    let chargeDateFrom = new Date(this.searchCondition.chargeDateFrom).getTime() / 1000;
    let chargeDateTo = new Date(this.searchCondition.chargeDateTo).setHours(23, 59, 59) / 1000;
    this.clientAdapter.getChargeList(chargeDateFrom, chargeDateTo).then((res: { id: string, customer: string, captured: boolean, captured_at: number, description: string, refunded: boolean, amount_refunded: number }[]) => {
      let targetColumnList: string[] = ['id', 'customer', 'amount', 'captured', 'captured_at', 'refunded', 'amount_refunded', 'description'];
      let csvContent: string = '';
      csvContent += '課金ID,顧客ID,金額,支払処理完了フラグ,請求日時,返金済フラグ,返金額,メモ';
      csvContent += '\n';
      // 選択したカラムの値を渡されたオブジェクトから抽出する
      res.forEach(target => {
        let targetValueList: string[] = [];
        targetColumnList.forEach(targetColumn => {
          if (targetColumn === 'captured_at') {
            targetValueList.push(new Date(target[targetColumn] * 1000).toLocaleDateString())
          } else {
            //@ts-ignore
            targetValueList.push(target[targetColumn]);
          }
        });
        for (let j = 0; j < targetValueList.length; j++) {
          csvContent += targetValueList[j];
          if (j !== targetValueList.length - 1) {
            csvContent += ',';
          }
        }
        csvContent += '\n';
      });
      let blob = new Blob([csvContent], {type: 'text/csv'});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'charges' + this.searchCondition.chargeDateFrom + '_' + this.searchCondition.chargeDateTo + '.csv';
      link.click();
    }).catch((err) => {
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'error';
      this.snackbar.text = err.data.message;
    });
  }
}
