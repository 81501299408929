































































import {VueEx} from '@/common/core/VueEx';
import {Component, Prop} from 'vue-property-decorator';

@Component({})
export default class ExportRoom extends VueEx {
  private allFieldSelected: boolean = false;
  private columnList: { name: string, isTarget: boolean }[] = [
    {
      name: 'id',
      isTarget: false,
    },
    {
      name: 'name',
      isTarget: false,
    },
    {
      name: 'area',
      isTarget: false,
    },
    {
      name: 'capacity',
      isTarget: false,
    },
    {
      name: 'dayNormalPrice',
      isTarget: false,
    },
    {
      name: 'dayCreditPrice',
      isTarget: false,
    },
    {
      name: 'dayEarlyPrice',
      isTarget: false,
    },
    {
      name: 'midNormalPrice',
      isTarget: false,
    },
    {
      name: 'midCreditPrice',
      isTarget: false,
    },
    {
      name: 'midEarlyPrice',
      isTarget: false,
    },
    {
      name: 'material',
      isTarget: false,
    },
    {
      name: 'vertical',
      isTarget: false,
    },
    {
      name: 'width',
      isTarget: false,
    },
    {
      name: 'created_at',
      isTarget: false,
    },
    {
      name: 'updated_at',
      isTarget: false,
    },
  ];

  @Prop({default: []})
  private targets!: { id: number, name: string, area: number, capacity: number, dayNormalPrice: string, dayCreditPrice: string, dayEarlyPrice: string, midNormalPrice: string, midCreditPrice: string, midEarlyPrice: string, studioImage: string, material: string, vertical: number, width: number, created_at: string, updated_at: string }[];


  /**
   * 全てのカラムを選択する
   * @private
   */
  private selectAllFields() {
    this.columnList.forEach(column => {
      column.isTarget = this.allFieldSelected;
    });
  }

  /**
   * データをcsvに出力する
   * @private
   */
  private exportAsCSV() {
    let targetColumnList: string[] = [];
    let csvContent: string = '';
    // 選択したカラムを抽出する
    this.columnList.forEach(column => {
      if (column.isTarget) {
        targetColumnList.push(column.name);
      }
    });
    // 選択したカラムをCSVのカラムにする
    for (let i = 0; i < targetColumnList.length; i++) {
      csvContent += targetColumnList[i];
      if (i !== targetColumnList.length - 1) {
        csvContent += ',';
      }
    }
    csvContent += '\n';
    // 選択したカラムの値を渡されたオブジェクトから抽出する
    this.targets.forEach(target => {
      let targetValueList: string[] = [];
      targetColumnList.forEach(targetColumn => {
        //@ts-ignore
        targetValueList.push(target[targetColumn]);
      });
      for (let j = 0; j < targetValueList.length; j++) {
        csvContent += targetValueList[j];
        if (j !== targetValueList.length - 1) {
          csvContent += ',';
        }
      }
      csvContent += '\n';
    });
    let now = new Date();
    let blob = new Blob([csvContent], {type: 'text/csv'});
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'rooms_' + now.toLocaleDateString() + '_' + now.toLocaleTimeString() + '.csv';
    link.click();
  }
}
