




































































import RoomDetail from './individual/RoomDetail.vue';
import EditRoom from './individual/EditRoom.vue';
import {VueEx} from '@/common/core/VueEx';
import {Component} from 'vue-property-decorator';
// import DeleteRoom from './individual/DeleteRoom.vue';

@Component({
  components: {
    RoomDetail,
    EditRoom,
    // DeleteRoom,
  }
})
export default class IndividualRoom extends VueEx {
  private rsv_items: { text: string, disabled: boolean, href: string }[] = [
    {
      text: 'ダッシュボード',
      disabled: false,
      href: '/',
    },
    {
      text: 'Room',
      disabled: false,
      href: '/room',
    },
    {
      text: '',
      disabled: true,
      href: '/individualRoom',
    },
  ];
  private tab: number = Number(this.$route.query.tab);
  private items: { page: string, icon: string }[] = [
    {
      page: '詳細',
      icon: 'information',
    },
    {
      page: '編集',
      icon: 'pencil',
    },
    // {
    //   page: '削除',
    //   icon: 'close-thick',
    // },
  ];
  private id: any = this.$route.query.id;

}
