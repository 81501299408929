




































































import ReservationDetail from './individual/ReservationDetail.vue';
import EditReservation from './individual/EditReservation.vue';
import DeleteReservation from './individual/DeleteReservation.vue';
import {VueEx} from '../../common/core/VueEx';
import {Component} from 'vue-property-decorator';

@Component({
  components: {
    ReservationDetail,
    EditReservation,
    DeleteReservation,
  }
})
export default class IndividualReservation extends VueEx {
  private rsv_items: { text: string, disabled: boolean, href: string }[] = [
    {
      text: 'ダッシュボード',
      disabled: false,
      href: '/',
    },
    {
      text: 'Reservation',
      disabled: false,
      href: '/reservation',
    },
    {
      text: 'Reservation #',
      disabled: true,
      href: '/individualReservation',
    },
  ];
  private tab: number = Number(this.$route.query.tab);
  // 予約がキャンセル済か
  private canceledFlg: boolean = this.$route.query.canceledFlg === 'true';
  private items: { page: string, icon: string, show: boolean }[] = [
    {
      page: '詳細',
      icon: 'information',
      show: true,
    },
    {
      page: '編集',
      icon: 'pencil',
      show: true,
    },
    {
      page: '予約キャンセル',
      icon: 'close-thick',
      show: !this.canceledFlg,// 予約がキャンセル済の場合、このタブを表示しない
    },
  ];
  id: any = this.$route.query.id;

}
