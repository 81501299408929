
























import {VueEx} from '../../../common/core/VueEx';
import {Component} from 'vue-property-decorator';

@Component({})
export default class AttendanceDetail extends VueEx {
  private columns: string[] = [
    'Intime',
    'Outtime',
    'User Id',
    'Created At',
    'Updated At'
  ];
  private detail: string[] = [];

  created() {
    this.clientAdapter.getAttendanceById(Number(this.$route.query.id)).then((res: {id: number, intime: string, outtime: string, user_id: number, created_at: string, updated_at: string}) => {
      this.detail = [
        res.intime,
        res.outtime,
        res.user_id.toString(),
        new Date(res.created_at).toLocaleString(),
        new Date(res.updated_at).toLocaleString(),
      ];
    });
  }
}
