





































































































































































































































































































































































































































import {VueEx} from '@/common/core/VueEx';
import {Component} from 'vue-property-decorator';

@Component({})
export default class EditRoom extends VueEx {
  private id: number = 0;
  private name: string = '';
  private area: number = 0;
  private capacity: number = 0;
  private dayNormalPrice: number = 0;
  private dayCreditPrice: number = 0;
  private dayEarlyPrice: number = 0;
  private midNormalPrice: number = 0;
  private midCreditPrice: number = 0;
  private midEarlyPrice: number = 0;
  private storeId: number = 0;
  private material: string = '';
  private vertical: number = 0;
  private width: number = 0;
  private reader: FileReader = new FileReader();
  private openingHour: string = '';
  private closingHour: string = '';
  private is_park: boolean = false;
  private isValidRoomForm: boolean = false;
  private items: { title: string, placeholder: string, hint: string, rules: any }[] = [
    {
      title: 'Name',
      placeholder: '',
      hint: '必須 最大文字数: 255 半角英数字のみ',
      rules: {
        required: (value: string) => !!value || '必須 最大文字数: 255',
        counter: (value: string) => value.length <= 255 || '必須 最大文字数: 255',
        regex: (value: string) => {
          const pattern = /^[0-9a-zA-Z"'\s]*$/;
          return pattern.test(value) || '半角英数字で入力してください';
        }
      },
    },
    {
      title: 'Area',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        regex: (value: string) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || '半角数字で入力してください';
        }
      },
    },
    {
      title: 'Capacity',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        regex: (value: string) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || '半角数字で入力してください';
        }
      },
    },
    {
      title: 'DayNormalPrice',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        regex: (value: string) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || '半角数字で入力してください';
        }
      },
    },
    {
      title: 'DayCreditPrice',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        regex: (value: string) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || '半角数字で入力してください';
        }
      },
    },
    {
      title: 'DayEarlyPrice',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        regex: (value: string) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || '半角数字で入力してください';
        }
      },
    },
    {
      title: 'MidNormalPrice',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        regex: (value: string) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || '半角数字で入力してください';
        }
      },
    },
    {
      title: 'MidCreditPrice',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        regex: (value: string) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || '半角数字で入力してください';
        }
      },
    },
    {
      title: 'MidEarlyPrice',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        regex: (value: string) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || '半角数字で入力してください';
        }
      },
    },
    {
      title: 'StoreId',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        regex: (value: string) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || '半角数字で入力してください';
        }
      },
    },
    {
      title: 'Material',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須 最大文字数: 255',
        counter: (value: string) => value.length <= 255 || '必須 最大文字数: 255',
        regex: (value: string) => {
          const pattern = /^[ぁ-んァ-ン一-龥]*$/;
          return pattern.test(value) || '全角文字で入力してください';
        }
      },
    },
    {
      title: 'Vertical',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        regex: (value: string) => {
          const pattern = /^[0-9]+(\.[0-9]+)*$/;
          return pattern.test(value) || 'xx.x形式で入力してください';
        }
      },
    },
    {
      title: 'Width',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        regex: (value: string) => {
          const pattern = /^[0-9]+(\.[0-9]+)*$/;
          return pattern.test(value) || 'xx.x形式で入力してください';
        }
      },
    },
    {
      title: 'OpeningHour',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        regex: (value: string) => {
          const pattern = /^[0-9]{1,2}:[0-9]{1,2}$/;
          return pattern.test(value) || 'HH:mm形式で入力してください'
        }
      }
    },
    {
      title: 'ClosingHour',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        regex: (value: string) => {
          const pattern = /^[0-9]{1,2}:[0-9]{1,2}$/;
          return pattern.test(value) || 'HH:mm形式で入力してください'
        }
      }
    },
    {
      title: 'IsPark',
      placeholder: '',
      hint: 'スタジオ区分がParkの場合はチェックしてください',
      rules: {}
    }
  ];
  private snackbar: { color: string, text: string, display: boolean, mode: string, x: string, y: string, timeout: number } = {
    color: '',
    text: '',
    display: false,
    mode: 'multi-line',
    x: 'right',
    y: 'top',
    timeout: 6000,
  };

  created() {
    this.clientAdapter.getStudioInfoById(Number(this.$route.query.id)).then((res: { id: number, name: string, area: number, capacity: number, dayNormalPrice: number, dayCreditPrice: number, dayEarlyPrice: number, MidNormalPrice: number, MidCreditPrice: number, MidEarlyPrice: number, created_at: string, updated_at: string, store_id: number, material: string, vertical: number, width: number, openingHour: string, closingHour: string, is_park: boolean }) => {
      this.id = res.id;
      this.name = res.name;
      this.area = res.area;
      this.capacity = res.capacity;
      this.dayNormalPrice = res.dayNormalPrice;
      this.dayCreditPrice = res.dayCreditPrice;
      this.dayEarlyPrice = res.dayEarlyPrice;
      this.midNormalPrice = res.MidNormalPrice;
      this.midCreditPrice = res.MidCreditPrice;
      this.midEarlyPrice = res.MidEarlyPrice;
      this.storeId = res.store_id;
      this.material = res.material;
      this.vertical = res.vertical;
      this.width = res.width;
      this.openingHour = res.openingHour;
      this.closingHour = res.closingHour;
      this.is_park = res.is_park;
    });
  }

  /**
   * 部屋を更新する
   * @private
   */
  private updateRoom() {
    if (!this.isValidRoomForm) {
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'error';
      this.snackbar.text = '入力内容に誤りがあります。再度内容をご確認の上、保存してください。';
      return;
    }
    this.clientAdapter.updateRoom(this.id, this.name, this.area, this.capacity, this.dayNormalPrice, this.dayCreditPrice, this.dayEarlyPrice, this.midNormalPrice, this.midCreditPrice, this.midEarlyPrice, this.storeId, this.material, this.vertical, this.width, this.openingHour, this.closingHour, this.is_park).then(() => {
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'success';
      this.snackbar.text = '部屋の登録に成功しました。';
      // 項目クリア
    }).catch(() => {
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'error';
      this.snackbar.text = '部屋の登録に失敗しました。しばらく経ってから再度お試しください。';
    });
  }
}
