
























import {VueEx} from '@/common/core/VueEx';
import {Component} from 'vue-property-decorator';

@Component({})
export default class RoomDetail extends VueEx {
  private columns: string[] = ['Name', 'Area', 'Capacity', 'DayNormalPrice', 'DayCreditPrice', 'DayEarlyPrice', 'MidNormalPrice', 'MidCreditPrice', 'MidEarlyPrice', 'Material', 'Vertical', 'Width', 'OpeningHour', 'ClosingHour', 'IsPark', 'CreatedAt', 'UpdatedAt'];
  private detail: string[] = [];

  created() {
    this.clientAdapter.getStudioInfoById(Number(this.$route.query.id)).then((res: { id: number, name: string, area: number, capacity: number, dayNormalPrice: number, dayCreditPrice: number, dayEarlyPrice: number, MidNormalPrice: number, MidCreditPrice: number, MidEarlyPrice: number, created_at: string, updated_at: string, store_id: number, material: string, vertical: number, width: number, openingHour: string, closingHour: string, is_park: boolean }) => {
      this.detail = [
        res.name,
        res.area + ' ㎡',
        res.capacity + ' people',
        '¥ ' + res.dayNormalPrice.toLocaleString(),
        '¥ ' + res.dayCreditPrice.toLocaleString(),
        '¥ ' + res.dayEarlyPrice.toLocaleString(),
        '¥ ' + res.MidNormalPrice.toLocaleString(),
        '¥ ' + res.MidCreditPrice.toLocaleString(),
        '¥ ' + res.MidEarlyPrice.toLocaleString(),
        res.material,
        res.vertical + ' m',
        res.width + ' m',
        res.openingHour,
        res.closingHour,
        String(res.is_park),
        new Date(res.created_at).toLocaleString(),
        new Date(res.updated_at).toLocaleString()
      ];
    });
  }
}
