
























import {VueEx} from '@/common/core/VueEx';
import {Component} from 'vue-property-decorator';

@Component({})
export default class OptionDetail extends VueEx {
  private columns: string[] = ['Name', 'Store', 'Category', 'Price', 'Unit', 'Description', 'MaxRentalAmount', 'Image', 'CreatedAt', 'UpdatedAt'];
  private detail: (string | number)[] = [];
  private categoryMap: { [key: number]: string } = {
   1: '照明機材',
   2: '音響機材',
   3: 'カメラ',
   4: 'レンズ',
   5: '三脚',
   6: 'カメラアクセサリー',
   7: '撮影関連機材',
   8: 'その他'
 };
  private storeMap: { [key: number]: string } = {
   1: '西早稲田',
   2: '幡ヶ谷',
   3: '渋谷',
   4: '目白',
 };


  created() {
    this.clientAdapter.getOptionById(Number(this.$route.query.id)).then((res: { id: number, option_id: number, name: string, price: number, unit: number, description: string, max_rental_amount: number, image: string, memo: string,  created_at: string, updated_at: string, category_id: number }) => {
      this.detail = [
        res.name,
        '',
        this.getCategoryName(res.category_id),
        '¥ ' + res.price,
        res.unit,
        res.description,
        res.max_rental_amount,
        res.image,
        new Date(res.created_at).toLocaleString(),
        new Date(res.updated_at).toLocaleString()
      ];

    });
     this.clientAdapter.getStoreOptionRefsByOptionId(Number(this.$route.query.id)).then((res: { id: number, store_id: number, option_id: number }[]) => {
        if (res.length === 1) {
          const store_id = res[0].store_id;
          this.detail[1] = this.getStoreName(store_id);
          this.$forceUpdate();
        } 
        else if (res.length > 1){
          const storeArray: string[] = [];
          res.forEach((store) =>{
            const storeName =  this.getStoreName(store.store_id);
            storeArray.push(storeName);
          });
          this.detail[1] = storeArray.join(', ');
          this.$forceUpdate();
        }
      });
  }
   // カテゴリーIDからカテゴリー名に変更するメソッド
  private getCategoryName(categoryId: number): string {
    if (categoryId === 99) {
      return '削除済み';
    }
    return this.categoryMap[categoryId] || '非表示中';
  }
   // 店舗IDから店舗名に変更するメソッド
  private getStoreName(storeId: number): string {
    if (storeId === 99) {
      return '削除済み';
    }
    return this.storeMap[storeId] || '非表示中';
  }
}
