






















































































































import {VueEx} from '@/common/core/VueEx';
import {Component} from 'vue-property-decorator';

@Component({})
export default class NewRoomImage extends VueEx {
  private storeId!: number;
  private roomId!: number;
  private showPriority!: number;
  private image!: File;
  private items: { title: string, placeholder: string, hint: string }[] = [
    {
      title: '部屋名',
      placeholder: '選択してください',
      hint: '部屋を選択してください。',
    },
    {
      title: '画像表示順',
      placeholder: '1',
      hint: 'ユーザ向け予約詳細入力画面での表示順を指定してください。',
    },
    {
      title: '画像',
      placeholder: '',
      hint: '画像をアップロードしてください。',
    },
  ];
  private rooms: {id: number, name: string}[] = [];
  private reader: FileReader = new FileReader();
  private imageUrl!: string;
  private snackbar: {color: string, text: string, display: boolean, mode: string, x: string, y: string, timeout: number} = {
    color: '',
    text: '',
    display: false,
    mode: 'multi-line',
    x: 'right',
    y: 'top',
    timeout: 6000,
  };

  created() {
    // TODO: 店舗が増えたら、ここを活性化する
    // this.clientAdapter.getStoreInfo()...
    // 部屋情報を取得する
    this.clientAdapter.getAllStudioInfo().then((res: {id:number, name: string}[]) => {
      res.forEach(room => {
        this.rooms.push(
            {
              id: room.id,
              name: room.name,
            }
        );
      });
    });
  }

  private onImageChange(){
    this.reader.readAsDataURL(this.image)
    this.reader.onload = (e) => {
      // @ts-ignore
      this.blob = e.target.result;
    }
  }

  /**
   * スタジオ画像を保存する
   * @private
   */
  private onSaveClick(){
    // 単項目チェック
    let message: string = '';
    if(!this.roomId) {
      message.concat('部屋名が選択されていません')
    }
    if(!this.showPriority) {
      message.concat('画像表示順が入力されていません')
    }
    if(message) {
      this.snackbar.display = true;
      this.snackbar.color = 'error';
      this.snackbar.text = message;
      return;
    }
    // 画像登録
    this.clientAdapter.createRoomImage(this.roomId, this.imageUrl, this.showPriority).then(() => {
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'success';
      this.snackbar.text = '画像の保存に成功しました。';
      // 項目クリア
      this.storeId = 0;
      this.roomId = 0;
      // @ts-ignore
      this.showPriority = null;
      // @ts-ignore
      this.image = null;
    }).catch(() => {
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'error';
      this.snackbar.text = '画像の保存に失敗しました。しばらく経ってから再度お試しください。';
    });
  }

}
