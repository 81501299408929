
























import {VueEx} from '../../../common/core/VueEx';
import {Component} from 'vue-property-decorator';

@Component({})
export default class CouponDetail extends VueEx {
  private columns: string[] = [
    'Code',
    'Name',
    'ValidStartDate',
    'ValidEndDate',
    'DiscountRate',
    'ActiveFlg',
    'Created At',
    'Updated At'
  ];
  private detail: string[] = [];

  created() {
    this.clientAdapter.getCouponById(Number(this.$route.query.id))
        .then((res: { id: number, code: string, name: string, validStartDate: string, validEndDate: string, discountRate: number, activeFlg: boolean, created_at: string, updated_at: string }) => {
          this.detail = [
            res.code,
            res.name,
            new Date(res.validStartDate).toLocaleDateString(),
            new Date(res.validEndDate).toLocaleDateString(),
            res.discountRate.toString(),
            res.activeFlg.toString(),
            new Date(res.created_at).toLocaleString(),
            new Date(res.updated_at).toLocaleString()
          ];
        });
  }
}
