
























































































































































































































































































import {VueEx} from '@/common/core/VueEx';
import {Component, Watch} from 'vue-property-decorator';
import ExportReservation from '@/views/reservation/ExportReservation.vue';
import ReservationUserDetail from '@/views/reservation/individual/ReservationUserDetail.vue';

@Component({
  components: {ExportReservation, ReservationUserDetail}
})
export default class ReservationTable extends VueEx {
  private headers: { text: string, value: string, width: number }[] = [
    {
      text: 'Actions',
      value: 'actions',
      width: 125,
    },
    {
      text: 'Id',
      value: 'id',
      width: 70,
    },
    {
      text: 'CreateTime',
      value: 'createTime',
      width: 200,
    },
    {
      text: 'ReserveStartTime',
      value: 'reserveStartTime',
      width: 200,
    },
    {
      text: 'ReserveEndTime',
      value: 'reserveEndTime',
      width: 200,
    },
    {
      text: 'Room',
      value: 'roomName',
      width: 120,
    },
    {
      text: 'MemberId',
      value: 'memberId',
      width: 120,
    },
    {
      text: 'UserName',
      value: 'userName',
      width: 120,
    },
    {
      text: 'HowToPay',
      value: 'howToPay',
      width: 190,
    },
    {
      text: 'PayJpBillingId',
      value: 'pay_jp_billing_id',
      width: 120,
    },
    {
      text: 'ReserveStatus',
      value: 'reserveStatus',
      width: 130,
    },
    {
      text: 'CancelType',
      value: 'cancelType',
      width: 120,
    },
  ];
  private reservations: { id: number, userTel: string, userEmail: string, createTime: string, reserveStartTime: string, reserveEndTime: string, midFlg: boolean, earlyFlg: boolean, howToPay: string, billing: string, studioBilling: string, optionBilling: string, isCommercialUse: boolean, paymentFlg: string, cancelDate: string, cancelType: string, couponCode: string, userName: string, memberId: number, roomName: string, reserveStatus: string, highLevelUsage: string, detailUsage: string, created_at: string, updated_at: string, memo: string, memoForAdmin: string, pay_jp_billing_id: string, is_payment_skipped: boolean }[] = [];
  private chosenColumnOptions: string[] = [
    'エクスポートする',
  ];
  private snackbar: { color: string, text: string, display: boolean, mode: string, x: string, y: string, timeout: number } = {
    color: '',
    text: '',
    display: false,
    mode: 'multi-line',
    x: 'right',
    y: 'top',
    timeout: 6000,
  };
  private selected: { id: number, createTime: string, reserveStartTime: string, reserveEndTime: string, midFlg: boolean, earlyFlg: boolean, howToPay: string, billing: string, studioBilling: string, optionBilling: string, isCommercialUse: boolean, paymentFlg: boolean, cancelDate: string, cancelType: string, couponCode: string, userName: string, memberId: string, roomName: string, reserveStatus: number, highLevelUsage: string, detailUsage: string, created_at: string, updated_at: string, memo: string, memoForAdmin: string }[] = [];
  private exportDialogFlg: boolean = false;
  private partialExportDialogFlg: boolean = false;
  private totalCount: number = 0;
  private loading: boolean = false;
  private options: any = {};
  private showSearchCondition: boolean = false;
  private itemsPerPage: number = 0;
  // @ts-ignore
  private searchCondition: { id: number, userTel: string, userEmail: string, createDate: string, reserveDateFrom: string, reserveDateTo: string, memberId: number, userName: string, roomId: number, pay_jp_billing_id: string } = {};
  private rooms: { id: number, name: string }[] = [];
  private loader = '';
  private menu1: boolean = false;
  private menu2: boolean = false;
  private localStorageKeyForSearchCondition: string = 'reservationSearchCondition';
  private userDetailDialog: boolean = false;
  private selectedUserId: string = "";


  @Watch('options')
  handler() {
    this.getReservations();
  }

  created() {
    if (localStorage.getItem(this.localStorageKeyForSearchCondition)) {
      this.searchCondition = JSON.parse(localStorage.getItem(this.localStorageKeyForSearchCondition)!);
    }
    this.clientAdapter.getAllStudioInfo().then((res: { id: number, name: string }[]) => {
      this.rooms.push({
        // @ts-ignore
        id: null,
        name: 'All',
      })
      res.forEach(room => {
        this.rooms.push({
          id: room.id,
          name: room.name,
        });
      });
    });
  }

  beforeDestroy() {
    // 検索条件を保存
    localStorage.setItem(this.localStorageKeyForSearchCondition, JSON.stringify(this.searchCondition));
  }

  handlePagination(newPagination: { itemsPerPage: number }) {
    this.itemsPerPage = newPagination.itemsPerPage;
  }

  /**
   * 選択項目を...のリストを押下したときの処理
   * @param index
   * @private
   */
  private processChosenData(index: number) {
    if (this.selected.length === 0) {
      this.snackbar.display = true;
      this.snackbar.color = 'error';
      this.snackbar.text = '対象データを選択してください';
      return;
    }
    if (index === 0) {
      this.partialExportDialogFlg = true;
    }
  }

  private exportReservations() {
    this.snackbar.display = true;
    this.snackbar.color = 'success';
    this.snackbar.text = 'Reservationをエクスポートしました';
  }

  /**
   * データエクスポートダイアログを表示する
   */
  private showExportDialog() {
    this.exportDialogFlg = true;
  }

  private dataInfo(id: number, index: number) {
    let canceledFlg = this.reservations[index].cancelDate !== '';
    //@ts-ignore
    this.$router.push({path: '/individualReservation', query: {id: id, tab: 0, canceledFlg: canceledFlg}});
  }

  private editData(id: number, index: number) {
    let canceledFlg = this.reservations[index].cancelDate !== '';
    //@ts-ignore
    this.$router.push({path: '/individualReservation', query: {id: id, tab: 1, canceledFlg: canceledFlg}});
  }

  private deleteData(id: number) {
    //@ts-ignore
    this.$router.push({path: '/individualReservation', query: {id: id, tab: 2}});
  }

  private getReservations() {
    this.loading = true;
    this.reservations = [];
    this.clientAdapter.getReservations(this.options, this.searchCondition).then((res: { total: number, reservations: { id: number, createTime: string, reserveStartTime: string, reserveEndTime: string, midFlg: boolean, earlyFlg: boolean, howToPay: number, billing: number, studioBilling: number, optionBilling: number, isCommercialUse: boolean, paymentFlg: boolean, cancelDate: string, cancelType: number, couponCode: string, userName: string, userTel: string, userEmail: string, user_id: number, roomName: string, reserveStatus: number, created_at: string, updated_at: string, highLevelUsage: string, detailUsage: string, numberOfUsers: number, memo: string, memoForAdmin: string, pay_jp_billing_id: string, is_payment_skipped: boolean }[] }) => {
      res.reservations.forEach(reservation => {
        this.reservations.push({
          id: reservation.id,
          createTime: new Date(reservation.createTime).toLocaleString(),
          reserveStartTime: new Date(reservation.reserveStartTime).toLocaleString(),
          reserveEndTime: new Date(reservation.reserveEndTime).toLocaleString(),
          midFlg: reservation.midFlg,
          earlyFlg: reservation.earlyFlg,
          howToPay: this.config.reservation.howToPay[reservation.howToPay],
          billing: reservation.billing.toString(),
          studioBilling: reservation.studioBilling.toString(),
          optionBilling: reservation.optionBilling.toString(),
          isCommercialUse: reservation.isCommercialUse,
          paymentFlg: reservation.paymentFlg ? '支払い済み' : '未支払い',
          cancelDate: reservation.cancelDate == null ? '' : new Date(reservation.cancelDate).toLocaleString(),
          cancelType: this.config.reservation.cancelType[reservation.cancelType],
          couponCode: reservation.couponCode,
          userName: reservation.userName,
          userTel: reservation.userTel,
          userEmail: reservation.userEmail,
          memberId: reservation.user_id + 89,
          roomName: reservation.roomName,
          reserveStatus: this.config.reservation.reserveStatus[reservation.reserveStatus],
          highLevelUsage: reservation.highLevelUsage,
          detailUsage: reservation.detailUsage,
          created_at: new Date(reservation.created_at).toLocaleString(),
          updated_at: new Date(reservation.updated_at).toLocaleString(),
          memo: '"' + reservation.memo + '"', // CSV出力した時にセル内での改行を有効にするため、"を前後につけている
          memoForAdmin: '"' + reservation.memoForAdmin + '"',
          pay_jp_billing_id: reservation.pay_jp_billing_id,
          is_payment_skipped: reservation.is_payment_skipped,
        });
      });
      this.totalCount = res.total;
      this.loading = false;
    });
  }

  private deleteSearchCondition() {
    //@ts-ignore
    this.searchCondition = {}
  }

  private showUserDetails(userId: number) {
      this.selectedUserId = String(userId);
      this.userDetailDialog = true;
  }
}
