



























































import {VueEx} from '../../../common/core/VueEx';
import {Component} from 'vue-property-decorator';

@Component({})
export default class OptionDetail extends VueEx {
  private id: number = 0;
  private columns: string[] = [
    'Name',
    'Category Id',
    'Price',
    'Unit',
    'Description',
    'MaxRentalAmount',
    'Image',
    'Memo'
  ];
  private detail: (string | number)[] = [];
  private snackbar: { color: string, text: string, display: boolean, mode: string, x: string, y: string, timeout: number } = {
    color: '',
    text: '',
    display: false,
    mode: 'multi-line',
    x: 'right',
    y: 'top',
    timeout: 6000,
  };

  created() {
    this.clientAdapter.getOptionById(Number(this.$route.query.id)).then((res: { id: number, option_id: number, name: string, price: number, unit: number, description: string, max_rental_amount: number, image: string, memo: string,  created_at: string, updated_at: string, category_id: number }) => {
      this.id = res.id
      this.detail = [
        res.name,
        res.category_id,
        '¥ ' + res.price,
        res.unit,
        res.description,
        res.max_rental_amount,
        res.image,
        new Date(res.created_at).toLocaleString(),
        new Date(res.updated_at).toLocaleString()
      ];
    });
  }

  /**
   * オプションの削除を実行する
   * @private
   */
  private withdrawal() {
    this.clientAdapter.withdrawOptionByAdmin(this.id).then(() => {
      this.clientAdapter.withdrawStoreOptioRefByAdmin(this.id)
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'success';
      this.snackbar.text = 'オプションの削除に成功しました。';
      // 項目クリア
    }).catch((err) => {
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'error';
      this.snackbar.text = err.message;
    });
  }
}
