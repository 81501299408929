















































































































































































































import {VueEx} from '@/common/core/VueEx';
import {Component} from 'vue-property-decorator';
import ExportCoupon from '@/views/coupon/ExportCoupon.vue';

@Component({
  components: {ExportCoupon,}
})
export default class CouponTable extends VueEx {
  private headers: { text: string, value: string, filter: any, items: string[], conditionWords: string, show: boolean, width: number, sortable: any, selectedCondition: number, showTextField: boolean }[] = [
    {
      text: 'Actions',
      value: 'actions',
      filter: null,
      items: [],
      conditionWords: '',
      show: false,
      width: 100,
      sortable: false,
      selectedCondition: 0,
      showTextField: false,
    },
    {
      text: 'Id',
      value: 'id',
      filter: (value: any) => {
        if (!this.headers[1].conditionWords || !this.headers[1].show) {
          return true;
        }
        return value.toString().includes(this.headers[1].conditionWords);
      },
      items: ['を含む'],
      conditionWords: '',
      show: false,
      width: 70,
      sortable: true,
      selectedCondition: 0,
      showTextField: true
    },
    {
      text: 'Code',
      value: 'code',
      filter: (value: string) => {
        if (!this.headers[2].conditionWords || !this.headers[2].show) {
          return true;
        }
        return value.toString().includes(this.headers[2].conditionWords);
      },
      items: ['を含む'],
      conditionWords: '',
      show: false,
      width: 200,
      sortable: true,
      selectedCondition: 0,
      showTextField: true
    },
    {
      text: 'Name',
      value: 'name',
      filter: (value: string) => {
        if (!this.headers[3].conditionWords || !this.headers[3].show) {
          return true;
        }
        return value.toString().includes(this.headers[3].conditionWords);
      },
      items: ['を含む'],
      conditionWords: '',
      show: false,
      width: 200,
      sortable: true,
      selectedCondition: 0,
      showTextField: true
    },
    {
      text: 'ValidStartDate',
      value: 'validStartDate',
      filter: (value: string) => {
        if (!this.headers[4].show || !this.headers[4].conditionWords) {
          return true;
        }
        if (this.headers[4].selectedCondition === 0) {
          return value.substring(0, 9) === this.headers[4].conditionWords;
        } else if (this.headers[4].selectedCondition === 1) {
          return value.substring(0, 6) === this.headers[4].conditionWords;
        } else if (this.headers[4].selectedCondition === 2) {
          let date: string[] = this.headers[4].conditionWords.split('-');
          return new Date(value) <= new Date(Number(date[0]), Number(date[1]) - 1, Number(date[2]), 23, 59, 59);
        } else if (this.headers[4].selectedCondition === 3) {
          let date: string[] = this.headers[4].conditionWords.split('-');
          return new Date(value) >= new Date(Number(date[0]), Number(date[1]) - 1, Number(date[2]), 23, 59, 59);
        }
      },
      items: ['に日付が一致 (YYYY-MM-DD)', 'に月が一致 (YYYY-MM)', '以前 (YYYY-MM-DD)', '以降 (YYYY-MM-DD)'],
      conditionWords: '',
      show: false,
      width: 150,
      sortable: true,
      selectedCondition: 0,
      showTextField: true
    },
    {
      text: 'ValidEndDate',
      value: 'validEndDate',
      filter: (value: string) => {
        if (!this.headers[5].show || !this.headers[5].conditionWords) {
          return true;
        }
        if (this.headers[5].selectedCondition === 0) {
          return value.substring(0, 9) === this.headers[5].conditionWords;
        } else if (this.headers[5].selectedCondition === 1) {
          return value.substring(0, 6) === this.headers[5].conditionWords;
        } else if (this.headers[5].selectedCondition === 2) {
          let date: string[] = this.headers[5].conditionWords.split('-');
          return new Date(value) <= new Date(Number(date[0]), Number(date[1]) - 1, Number(date[2]), 23, 59, 59);
        } else if (this.headers[5].selectedCondition === 3) {
          let date: string[] = this.headers[5].conditionWords.split('-');
          return new Date(value) >= new Date(Number(date[0]), Number(date[1]) - 1, Number(date[2]), 23, 59, 59);
        }
      },
      items: ['に日付が一致 (YYYY-MM-DD)', 'に月が一致 (YYYY-MM)', '以前 (YYYY-MM-DD)', '以降 (YYYY-MM-DD)'],
      conditionWords: '',
      show: false,
      width: 150,
      sortable: true,
      selectedCondition: 0,
      showTextField: true
    },
    {
      text: 'DiscountRate',
      value: 'discountRate',
      filter: (value: number) => {
        if (!this.headers[6].show || !this.headers[6].conditionWords) {
          return true;
        }
        if (this.headers[6].selectedCondition === 0) {
          return value >= Number(this.headers[6].conditionWords);
        } else if (this.headers[6].selectedCondition === 1) {
          return value <= Number(this.headers[6].conditionWords);
        }
      },
      items: ['以上', '以下'],
      conditionWords: '',
      show: false,
      width: 150,
      sortable: true,
      selectedCondition: 0,
      showTextField: true
    },
    {
      text: 'ActiveFlg',
      value: 'activeFlg',
      filter: (value: string) => {
        if (!this.headers[7].show) {
          return true;
        }
        return value === this.headers[7].items[this.headers[7].selectedCondition];
      },
      items: ['true', 'false'],
      conditionWords: '',
      show: false,
      width: 120,
      sortable: true,
      selectedCondition: 0,
      showTextField: false
    },
    {
      text: 'Created at',
      value: 'created_at',
      filter: (value: string) => {
        if (!this.headers[8].show || !this.headers[8].conditionWords) {
          return true;
        }
        if (this.headers[8].selectedCondition === 0) {
          return value.substring(0, 9) === this.headers[8].conditionWords;
        } else if (this.headers[8].selectedCondition === 1) {
          return value.substring(0, 6) === this.headers[8].conditionWords;
        } else if (this.headers[8].selectedCondition === 2) {
          let date: string[] = this.headers[8].conditionWords.split('-');
          return new Date(value) <= new Date(Number(date[0]), Number(date[1]) - 1, Number(date[2]), 23, 59, 59);
        } else if (this.headers[8].selectedCondition === 3) {
          let date: string[] = this.headers[8].conditionWords.split('-');
          return new Date(value) >= new Date(Number(date[0]), Number(date[1]) - 1, Number(date[2]), 23, 59, 59);
        }
      },
      items: ['に日付が一致 (YYYY-MM-DD)', 'に月が一致 (YYYY-MM)', '以前 (YYYY-MM-DD)', '以降 (YYYY-MM-DD)'],
      conditionWords: '',
      show: false,
      width: 200,
      sortable: true,
      selectedCondition: 0,
      showTextField: true
    },
    {
      text: 'Updated at',
      value: 'updated_at',
      filter: (value: string) => {
        if (!this.headers[9].show || !this.headers[9].conditionWords) {
          return true;
        }
        if (this.headers[9].selectedCondition === 0) {
          return value.substring(0, 9) === this.headers[9].conditionWords;
        } else if (this.headers[9].selectedCondition === 1) {
          return value.substring(0, 6) === this.headers[9].conditionWords;
        } else if (this.headers[9].selectedCondition === 2) {
          let date: string[] = this.headers[9].conditionWords.split('-');
          return new Date(value) <= new Date(Number(date[0]), Number(date[1]) - 1, Number(date[2]), 23, 59, 59);
        } else if (this.headers[9].selectedCondition === 3) {
          let date: string[] = this.headers[9].conditionWords.split('-');
          return new Date(value) >= new Date(Number(date[0]), Number(date[1]) - 1, Number(date[2]), 23, 59, 59);
        }
      },
      items: ['に日付が一致 (YYYY-MM-DD)', 'に月が一致 (YYYY-MM)', '以前 (YYYY-MM-DD)', '以降 (YYYY-MM-DD)'],
      conditionWords: '',
      show: false,
      width: 200,
      sortable: true,
      selectedCondition: 0,
      showTextField: true
    },
  ];
  private coupons: { id: number, code: string, name: string, validStartDate: string, validEndDate: string, discountRate: number, activeFlg: boolean, created_at: string, updated_at: string }[] = [];
  private chosenColumnOptions: string[] = [
    'エクスポートする',
    // '削除する',
  ];
  private snackbar: { color: string, text: string, display: boolean, mode: string, x: string, y: string, timeout: number } = {
    color: '',
    text: '',
    display: false,
    mode: 'multi-line',
    x: 'right',
    y: 'top',
    timeout: 6000,
  };
  private selected: { id: number, code: string, name: string, validStartDate: string, validEndDate: string, discountRate: number, activeFlg: boolean, created_at: string, updated_at: string }[] = [];
  private search: string = '';
  private exportDialogFlg: boolean = false;
  private partialExportDialogFlg: boolean = false;

  /**
   * 検索条件の文字列を返却する。
   */
  get showDefault() {
    return function(selectedCondition: number, index: number) {
      //@ts-ignore
      return this.headers[index].items[selectedCondition];
    };
  }

  created() {
    this.clientAdapter.getAllCoupons().then((res: { id: number, code: string, name: string, validStartDate: string, validEndDate: string, discountRate: number, activeFlg: boolean, created_at: string, updated_at: string }[]) => {
      res.forEach(coupon => {
        this.coupons.push({
          id: coupon.id,
          code: coupon.code,
          name: coupon.name,
          validStartDate: new Date(coupon.validStartDate).toLocaleDateString(),
          validEndDate: new Date(coupon.validEndDate).toLocaleDateString(),
          discountRate: coupon.discountRate,
          activeFlg: coupon.activeFlg,
          created_at: new Date(coupon.created_at).toLocaleString(),
          updated_at: new Date(coupon.updated_at).toLocaleString(),
        });
      });
    });
  }

  /**
   * 検索条件を追加する
   * @param index
   * @private
   */
  private addCondition(index: number) {
    if (index === 0) {
      return;
    }
    this.headers[index].show = true;
  }

  /**
   * 検索条件を削除する
   * @param index
   * @private
   */
  private removeCondition(index: number) {
    this.headers[index].show = false;
    this.headers[index].conditionWords = '';
  }

  /**
   * 選択項目を...のリストを押下したときの処理
   * @param index
   * @private
   */
  private processChosenData(index: number) {
    if (this.selected.length === 0) {
      this.snackbar.display = true;
      this.snackbar.color = 'error';
      this.snackbar.text = '対象データを選択してください';
      return;
    }
    if (index === 0) {
      this.partialExportDialogFlg = true;
    }
  }

  private exportCoupons() {
    this.snackbar.display = true;
    this.snackbar.color = 'success';
    this.snackbar.text = 'Reservationをエクスポートしました';
  }

  /**
   * データエクスポートダイアログを表示する
   */
  private showExportDialog() {
    this.exportDialogFlg = true;
  }

  private dataInfo(id: number) {
    //@ts-ignore
    this.$router.push({path: '/individualCoupons', query: {id: id, tab: 0}});
  }

  private editData(id: number) {
    //@ts-ignore
    this.$router.push({path: '/individualCoupons', query: {id: id, tab: 1}});
  }

  // private deleteData(id: number) {
  //   //@ts-ignore
  //   this.$router.push({path: '/individualCoupons', query: {id: id, tab: 2}});
  // }

  /**
   * フィルター条件を変更する
   * @param index
   * @param i
   * @private
   */
  private changeFilterCondition(index: number, i: number) {
    this.headers[index].selectedCondition = i;
  }
}
