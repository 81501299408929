




































































































































































// @ts-ignore
import {Datetime} from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import {VueEx} from '@/common/core/VueEx';
import {Component} from 'vue-property-decorator';

@Component({
  components: {
    datetime: Datetime
  }
})
export default class EditAttendance extends VueEx {
  private id: number = 0;
  private inTime: string = '';
  private outTime: string = '';
  private userId: string = '';

  private items: { title: string, placeholder: string, hint: string, rules: any }[] = [
    {
      title: 'InTime',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
      }
    },
    {
      title: 'OutTime',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
      }
    },
    {
      title: 'UserId',
      placeholder: '',
      hint: '必須',
      rules: {
        required: (value: string) => !!value || '必須',
        format: (value: string) => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || '半角数字で入力してください';
        }
      },
    }
  ];
  private snackbar: { color: string, text: string, display: boolean, mode: string, x: string, y: string, timeout: number } = {
    color: '',
    text: '',
    display: false,
    mode: 'multi-line',
    x: 'right',
    y: 'top',
    timeout: 6000,
  };

  created() {
    this.clientAdapter.getAttendanceById(Number(this.$route.query.id)).then((res: { id: number, intime: string, outtime: string, user_id: number, created_at: string, updated_at: string }) => {
      this.id = res.id;
      this.inTime = new Date(res.intime).toISOString();
      this.outTime = res.outtime == null ? '' : new Date(res.outtime).toISOString()
      this.userId = res.user_id.toString();
    });
  }

  private save() {
    this.clientAdapter.updateAttendance(this.id, this.inTime, this.outTime, Number(this.userId)).then(() => {
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'success';
      this.snackbar.text = 'Attendanceの更新に成功しました。';
      // 項目クリア
    }).catch(() => {
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'error';
      this.snackbar.text = 'Attendanceの登録に失敗しました。しばらく経ってから再度お試しください。';
    });
  }
}
