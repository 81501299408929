














































































































































































































































































// @ts-ignore
import {Datetime} from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import {VueEx} from '@/common/core/VueEx';
import {Component} from 'vue-property-decorator';
import {Reg} from '@/common/core/common';

@Component({
  components: {
    datetime: Datetime
  },
})
export default class EditCoupon extends VueEx {
  private id: number = 0;
  private code: string = '';
  private name: string = '';
  private validStartDate: string = '';
  private validEndDate: string = '';
  private discountRate: string = '';
  private activeFlg: boolean = false;
  private couponForm: boolean = false;
  private snackbar: { color: string, text: string, display: boolean, mode: string, x: string, y: string, timeout: number } = {
    color: '',
    text: '',
    display: false,
    mode: 'multi-line',
    x: 'right',
    y: 'top',
    timeout: 6000,
  };
  private items: { title: string, placeholder: string, hint: string, rules: any }[] = [
    {
      title: 'Code',
      placeholder: '',
      hint: '・必須\n・最大255文字',
      rules: {
        required: (value: string) => !!value || '必須',
        regex: (v: string) => {
          return v.length <= 255;
        }
      },
    },
    {
      title: 'Name',
      placeholder: '',
      hint: '・必須\n・最大255文字',
      rules: {
        required: (value: string) => !!value || '必須',
        regex: (v: string) => {
          return v.length <= 255;
        }
      },
    },
    {
      title: 'ValidStartDate',
      placeholder: '',
      hint: '必須.',
      rules: {
        required: (value: string) => !!value || '必須',
      },
    },
    {
      title: 'ValidEndDate',
      placeholder: '',
      hint: '必須.',
      rules: {
        required: (value: string) => !!value || '必須',
      },
    },
    {
      title: 'DiscountRate',
      placeholder: '',
      hint: '・必須\n・整数\n・単位：%',
      rules: {
        required: (value: number) => !!value || '必須',
        regex: (v: string) => {
          return Reg.percentRegexp.test(v);
        }
      },
    },
    {
      title: 'ActiveFlg',
      placeholder: '',
      hint: '',
      rules: null,
    },
  ];

  private get isValidate(): boolean {
    return !this.couponForm || this.validStartDate === '' || this.validEndDate === '';
  }

  created() {
    this.id = Number(this.$route.query.id)
    this.clientAdapter.getCouponById(this.id).then((res: { id: number, code: string, name: string, validStartDate: string, validEndDate: string, discountRate: number, activeFlg: boolean, created_at: string, updated_at: string }) => {
      this.code = res.code;
      this.name = res.name;
      this.validStartDate = res.validStartDate;
      this.validEndDate = res.validEndDate;
      this.discountRate = res.discountRate.toString();
      this.activeFlg = res.activeFlg;
    })
  }

  private onSaveClick() {
    if (!this.couponForm || this.validStartDate === '' || this.validEndDate === '') {
      return;
    }
    this.clientAdapter.updateCouponByAdmin(this.id, this.code, this.name, this.validStartDate, this.validEndDate, Number(this.discountRate), this.activeFlg).then(() => {
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'success';
      this.snackbar.text = 'クーポンの更新に成功しました。';
      // 項目クリア
    }).catch(() => {
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'error';
      this.snackbar.text = 'クーポンの更新に失敗しました。しばらく経ってから再度お試しください。';
    });
  }
}
