



























































import {VueEx} from '../../../common/core/VueEx';
import {Component} from 'vue-property-decorator';

@Component({})
export default class UserDetail extends VueEx {
  private id: number = 0;
  private columns: string[] = [
    'Name',
    'Member Id',
    'Tel',
    'Status',
    'Grade',
    'Admin Flg',
    'Email',
    'Birthday',
    'Gender',
    'Genre',
    'Created At',
    'Updated At',
    'Memo'
  ];
  private detail: string[] = [];
  private email: any = this.$route.query.email;
  private snackbar: { color: string, text: string, display: boolean, mode: string, x: string, y: string, timeout: number } = {
    color: '',
    text: '',
    display: false,
    mode: 'multi-line',
    x: 'right',
    y: 'top',
    timeout: 6000,
  };

  created() {
    this.clientAdapter.getUserByEmail(this.$route.query.email as string).then((res: { id: number, name: string, member_id: number, tel: string, status: number, grade: number, admin_flg: boolean, email: string, birthday: string, gender: number, genre: string, /** 本当は取りたいけど取れない last_sign_in_at: string, failed_attempts: number, */ created_at: string, updated_at: string, memo: string }[]) => {
      this.id = res[0].id;
      this.detail = [
        res[0].name,
        res[0].member_id,
        res[0].tel,
        this.config.user.status[res[0].status],
        this.config.user.grade[res[0].grade],
        res[0].admin_flg,
        res[0].email,
        res[0].birthday,
        this.config.user.gender[res[0].gender],
        res[0].genre,
        // res[0].last_sign_in_at,
        // res[0].failed_attempts,
        new Date(res[0].created_at).toLocaleString(),
        new Date(res[0].updated_at).toLocaleString(),
        res[0].memo,
      ];
    });
  }

  /**
   * ユーザの退会を実行する
   * @private
   */
  private withdrawal() {
    this.clientAdapter.withdrawUserByAdmin(this.id).then(() => {
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'success';
      this.snackbar.text = '予約のキャンセルに成功しました。';
      // 項目クリア
    }).catch((err) => {
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'error';
      this.snackbar.text = err.message;
    });
  }
}
