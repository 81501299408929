









































































































































































































































































































































































//@ts-ignore
import {Datetime} from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import {VueEx} from '@/common/core/VueEx';
import {Component} from 'vue-property-decorator';
import {Reg} from '@/common/core/common';

@Component({
  components: {
    datetime: Datetime
  }
})
export default class NewUser extends VueEx {
  private id: number = 0;
  private name: string = '';
  private tel: string = '';
  private adminFlg: boolean = false;
  private email: string = '';
  private birthday: string = '';
  private genders: { id: number, gender: string }[] = [];
  private gender: number = 999;
  private genres!: string[];
  private genre: string = '';
  private grade: number = 999;
  private grades: { id: number, grade: string }[] = [];
  private status: number = 999;
  private statuses: {id: number, status: string}[] = [];
  private memo: string = '';
  private show1: boolean = false;
  private show2: boolean = false;
  private userForm: boolean = false;
  private snackbar: { color: string, text: string, display: boolean, mode: string, x: string, y: string, timeout: number } = {
    color: '',
    text: '',
    display: false,
    mode: 'multi-line',
    x: 'right',
    y: 'top',
    timeout: 6000,
  };
  private items: { title: string, hint: string, rules: any, placeholder: string }[] = [
    {
      title: 'Name',
      hint: '・必須\n・最大255文字',
      rules: {
        required: (value: string) => !!value || '必須',
        regex: (v: string) => {
          return v.length <= 255;
        }
      },
      placeholder: '',
    },
    {
      title: 'Tel',
      hint: '・必須\n・数字\n・ハイフン無し',
      rules: {
        required: (value: string) => !!value || '必須',
        regex: (v: string) => {
          return Reg.telRegexp.test(v) || 'Telの形式が不正です。数字かつxxx-xxxx-xxxx形式で入力してください。';
        }
      },
      placeholder: '',
    },
    {
      title: 'AdminFlg',
      hint: '',
      rules: null,
      placeholder: '',
    },
    {
      title: 'Email',
      hint: '・必須\n・xxxxx@xxxxx 形式',
      rules: {
        required: (value: string) => !!value || '必須',
        regex: (v: string) => {
          return Reg.mailAddressRegexp.test(v) || 'Emailの形式が不正です。xxxx@xxxxx 形式で入力してください。';
        }
      },
      placeholder: '',
    },
    {
      title: 'Password',
      hint: '・6文字以上20文字以下\n・大文字、小文字、数字を全て含める',
      rules: {
      },
      placeholder: '',
    },
    {
      title: 'PasswordConfirmation',
      hint: '',
      rules: {
      },
      placeholder: '',
    },
    {
      title: 'Birthday',
      hint: '',
      rules: null,
      placeholder: '',
    },
    {
      title: 'Gender',
      hint: '',
      rules: null,
      placeholder: '',
    },
    {
      title: 'Genre',
      hint: '',
      rules: null,
      placeholder: '',
    },
    {
      title: 'Grade',
      hint: '',
      rules: null,
      placeholder: '',
    },
    {
      title: 'Status',
      hint: '',
      rules: null,
      placeholder: '',
    },
    {
      title: 'Memo',
      hint: '',
      rules: {
         regex: (v: string) => {
          return v.length <= 65535 || '65535文字以内で入力してください。';
        }
      },
      placeholder: '',
    },
  ];

  private get isValidate(): boolean {
    return !this.userForm || this.birthday === '' || this.gender === 999 || this.genre === '' || this.grade === 999;
  }

  created() {
    Object.keys(this.config.user.gender).forEach(key => {
      this.genders.push({
        id: Number(key),
        gender: this.config.user.gender[key],
      });
    });
    Object.keys(this.config.user.grade).forEach(key => {
      this.grades.push({
        id: Number(key),
        grade: this.config.user.grade[key],
      });
    });
    Object.keys(this.config.user.status).forEach(key => {
      this.statuses.push({
        id: Number(key),
        status: this.config.user.status[key],
      });
    });
    this.genres = this.config.user.genre;
    this.clientAdapter.getUserByEmail(this.$route.query.email as string).then((res: { id: number, name: string, member_id: number, tel: string, status: number, grade: number, admin_flg: boolean, email: string, birthday: string, gender: number, genre: string, memo: string, /** 本当は取りたいけど取れない last_sign_in_at: string, failed_attempts: number, */ created_at: string, updated_at: string }[]) => {
      this.id = res[0].id;
      this.name = res[0].name;
      this.tel = res[0].tel;
      this.grade = res[0].grade;
      this.adminFlg = res[0].admin_flg;
      this.email = res[0].email;
      this.birthday = res[0].birthday;
      this.gender = res[0].gender;
      this.genre = res[0].genre;
      this.status = res[0].status;
      this.memo = res[0].memo;
      // res[0].last_sign_in_at;
      // res[0].failed_attempts;
    });
  }

  /**
   * 会員登録を行う
   * @private
   */
  private onSaveClick() {
    //@ts-ignore
    if (!this.userForm || this.birthday === '' || this.gender === 99 || this.genre === '' || this.grade === 999) {
      return;
    }
    this.clientAdapter.updateMemberByAdmin(this.id, this.email, this.name, this.tel, this.birthday.substring(0, 10), this.gender.toString(), this.genre, this.adminFlg, this.grade.toString(), this.status, this.memo).then(() => {
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'success';
      this.snackbar.text = '会員登録に成功しました。';
      // 項目クリア
    }).catch(() => {
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'error';
      this.snackbar.text = '会員登録に失敗しました。しばらく経ってから再度お試しください。';
    });
  }
}
