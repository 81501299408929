




































































import UserDetail from './individual/UserDetail.vue';
import EditUser from './individual/EditUser.vue';
import DeleteUser from './individual/DeleteUser.vue';
import {VueEx} from '@/common/core/VueEx';
import {Component} from 'vue-property-decorator';

@Component({
  components: {
    UserDetail,
    EditUser,
    DeleteUser,
  }
})
export default class IndividualUser extends VueEx {
  private rsv_items: { text: string, disabled: boolean, href: string }[] = [
    {
      text: 'ダッシュボード',
      disabled: false,
      href: '/',
    },
    {
      text: 'User',
      disabled: false,
      href: '/user',
    },
    {
      text: 'User #',
      disabled: true,
      href: '/individualUser',
    },
  ];
  private tab: number = Number(this.$route.query.tab);
  // 予約がキャンセル済か
  private withdrawalFlg: boolean = this.$route.query.withdrawalFlg === 'true';
  private items: { page: string, icon: string, show: boolean }[] = [
    {
      page: '詳細',
      icon: 'information',
      show: true,
    },
    {
      page: '編集',
      icon: 'pencil',
      show: true,
    },
    {
      page: '退会処理',
      icon: 'close-thick',
      show: !this.withdrawalFlg, // 退会済の場合、このタブを表示しない
    },
  ];
  email: any = this.$route.query.email;

}
