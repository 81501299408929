







































































































































































































































import {VueEx} from '@/common/core/VueEx';
import {Component} from 'vue-property-decorator';
import ExportRoom from '@/views/room/ExportRoom.vue';

@Component({
  components: {ExportRoom}
})
export default class RoomTable extends VueEx {
  private headers: { text: string, value: string, filter: any, items: string[], conditionWords: string, show: boolean, width: number, sortable: any, selectedCondition: number }[] = [
    {
      text: 'Actions',
      value: 'actions',
      filter: null,
      items: [],
      conditionWords: '',
      show: false,
      width: 100,
      sortable: false,
      selectedCondition: 0,
    },
    {
      text: 'Id',
      value: 'id',
      filter: (value: any) => {
        if (!this.headers[1].conditionWords || !this.headers[1].show) {
          return true;
        }
        return value.toString().includes(this.headers[1].conditionWords);
      },
      items: ['を含む'],
      conditionWords: '',
      show: false,
      width: 70,
      sortable: true,
      selectedCondition: 0,
    },
    {
      text: 'Name',
      value: 'name',
      filter: (value: any) => {
        if (!this.headers[2].conditionWords || !this.headers[2].show) {
          return true;
        }
        if (this.headers[2].selectedCondition === 0) {
          return value.toString().includes(this.headers[2].conditionWords);
        } else if (this.headers[2].selectedCondition === 1) {
          return value.toString() === this.headers[2].conditionWords;
        } else if (this.headers[2].selectedCondition === 2) {
          return value.toString().startsWith(this.headers[2].conditionWords);
        } else if (this.headers[2].selectedCondition === 3) {
          return value.toString().endsWith(this.headers[2].conditionWords);
        }
      },
      items: ['を含む', 'に完全に一致', 'で始まる', 'で終わる'],
      conditionWords: '',
      show: false,
      width: 100,
      sortable: true,
      selectedCondition: 0,
    },
    {
      text: 'StudioImage',
      value: 'studioImageUrl',
      filter: () => {
        return true;
      },
      items: ['-'],
      conditionWords: '',
      show: false,
      width: 150,
      sortable: true,
      selectedCondition: 0,
    },
    {
      text: 'Area (㎡)',
      value: 'area',
      filter: (value: number) => {
        if (!this.headers[4].show || !this.headers[4].conditionWords) {
          return true;
        }
        if (this.headers[4].selectedCondition === 0) {
          return value >= Number(this.headers[4].conditionWords);
        } else if (this.headers[4].selectedCondition === 1) {
          return value <= Number(this.headers[4].conditionWords);
        }
      },
      items: ['以上', '以下'],
      conditionWords: '',
      show: false,
      width: 100,
      sortable: true,
      selectedCondition: 0,
    },
    {
      text: 'Capacity (people)',
      value: 'capacity',
      filter: (value: number) => {
        if (!this.headers[5].show || !this.headers[5].conditionWords) {
          return true;
        }
        if (this.headers[5].selectedCondition === 0) {
          return value >= Number(this.headers[5].conditionWords);
        } else if (this.headers[5].selectedCondition === 1) {
          return value <= Number(this.headers[5].conditionWords);
        }
      },
      items: ['以上', '以下'],
      conditionWords: '',
      show: false,
      width: 150,
      sortable: true,
      selectedCondition: 0,
    },
    {
      text: 'DayNormalPrice (¥)',
      value: 'dayNormalPrice',
      filter: (value: string) => {
        if (!this.headers[6].show || !this.headers[6].conditionWords) {
          return true;
        }
        if (this.headers[6].selectedCondition === 0) {
          return Number(value.replace(/,/g, '')) >= Number(this.headers[6].conditionWords);
        } else if (this.headers[6].selectedCondition === 1) {
          return Number(value.replace(/,/g, '')) <= Number(this.headers[6].conditionWords);
        }
      },
      items: ['以上', '以下'],
      conditionWords: '',
      show: false,
      width: 160,
      sortable: true,
      selectedCondition: 0,
    },
    {
      text: 'DayCreditPrice (¥)',
      value: 'dayCreditPrice',
      filter: (value: string) => {
        if (!this.headers[7].show || !this.headers[7].conditionWords) {
          return true;
        }
        if (this.headers[7].selectedCondition === 0) {
          return Number(value.replace(/,/g, '')) >= Number(this.headers[7].conditionWords);
        } else if (this.headers[7].selectedCondition === 1) {
          return Number(value.replace(/,/g, '')) <= Number(this.headers[7].conditionWords);
        }
      },
      items: ['以上', '以下'],
      conditionWords: '',
      show: false,
      width: 160,
      sortable: true,
      selectedCondition: 0,
    },
    {
      text: 'DayEarlyPrice (¥)',
      value: 'dayEarlyPrice',
      filter: (value: string) => {
        if (!this.headers[8].show || !this.headers[8].conditionWords) {
          return true;
        }
        if (this.headers[8].selectedCondition === 0) {
          return Number(value.replace(/,/g, '')) >= Number(this.headers[8].conditionWords);
        } else if (this.headers[8].selectedCondition === 1) {
          return Number(value.replace(/,/g, '')) <= Number(this.headers[8].conditionWords);
        }
      },
      items: ['以上', '以下'],
      conditionWords: '',
      show: false,
      width: 160,
      sortable: true,
      selectedCondition: 0,
    },
    {
      text: 'MidNormalPrice (¥)',
      value: 'midNormalPrice',
      filter: (value: string) => {
        if (!this.headers[9].show || !this.headers[9].conditionWords) {
          return true;
        }
        if (this.headers[9].selectedCondition === 0) {
          return Number(value.replace(/,/g, '')) >= Number(this.headers[9].conditionWords);
        } else if (this.headers[9].selectedCondition === 1) {
          return Number(value.replace(/,/g, '')) <= Number(this.headers[9].conditionWords);
        }
      },
      items: ['以上', '以下'],
      conditionWords: '',
      show: false,
      width: 160,
      sortable: true,
      selectedCondition: 0,
    },
    {
      text: 'MidCreditPrice (¥)',
      value: 'midCreditPrice',
      filter: (value: string) => {
        if (!this.headers[10].show || !this.headers[10].conditionWords) {
          return true;
        }
        if (this.headers[10].selectedCondition === 0) {
          return Number(value.replace(/,/g, '')) >= Number(this.headers[10].conditionWords);
        } else if (this.headers[10].selectedCondition === 1) {
          return Number(value.replace(/,/g, '')) <= Number(this.headers[10].conditionWords);
        }
      },
      items: ['以上', '以下'],
      conditionWords: '',
      show: false,
      width: 160,
      sortable: true,
      selectedCondition: 0,
    },
    {
      text: 'MidEarlyPrice (¥)',
      value: 'midEarlyPrice',
      filter: (value: string) => {
        if (!this.headers[11].show || !this.headers[11].conditionWords) {
          return true;
        }
        if (this.headers[11].selectedCondition === 0) {
          return Number(value.replace(/,/g, '')) >= Number(this.headers[11].conditionWords);
        } else if (this.headers[11].selectedCondition === 1) {
          return Number(value.replace(/,/g, '')) <= Number(this.headers[11].conditionWords);
        }
      },
      items: ['以上', '以下'],
      conditionWords: '',
      show: false,
      width: 160,
      sortable: true,
      selectedCondition: 0,
    },
    {
      text: 'Material',
      value: 'material',
      filter: (value: string) => {
        if (!this.headers[12].show || !this.headers[12].conditionWords) {
          return true;
        }
        if (this.headers[12].selectedCondition === 0) {
          return value.toString().includes(this.headers[12].conditionWords);
        } else if (this.headers[12].selectedCondition === 1) {
          return value.toString() === this.headers[12].conditionWords;
        } else if (this.headers[12].selectedCondition === 2) {
          return value.toString().startsWith(this.headers[12].conditionWords);
        } else if (this.headers[12].selectedCondition === 3) {
          return value.toString().endsWith(this.headers[12].conditionWords);
        }
      },
      items: ['を含む', 'に完全に一致', 'で始まる', 'で終わる'],
      conditionWords: '',
      show: false,
      width: 150,
      sortable: true,
      selectedCondition: 0,
    },
    {
      text: 'Vertical (m)',
      value: 'vertical',
      filter: (value: number) => {
        if (!this.headers[13].show || !this.headers[13].conditionWords) {
          return true;
        }
        if (this.headers[13].selectedCondition === 0) {
          return value >= Number(this.headers[13].conditionWords);
        } else if (this.headers[13].selectedCondition === 1) {
          return value <= Number(this.headers[13].conditionWords);
        }
      },
      items: ['以上', '以下'],
      conditionWords: '',
      show: false,
      width: 130,
      sortable: true,
      selectedCondition: 0,
    },
    {
      text: 'Width (m)',
      value: 'width',
      filter: (value: number) => {
        if (!this.headers[14].show || !this.headers[14].conditionWords) {
          return true;
        }
        if (this.headers[14].selectedCondition === 0) {
          return value >= Number(this.headers[14].conditionWords);
        } else if (this.headers[14].selectedCondition === 1) {
          return value <= Number(this.headers[14].conditionWords);
        }
      },
      items: ['以上', '以下'],
      conditionWords: '',
      show: false,
      width: 130,
      sortable: true,
      selectedCondition: 0,
    },
    {
      text: 'Created at',
      value: 'created_at',
      filter: (value: string) => {
        if (!this.headers[15].show || !this.headers[15].conditionWords) {
          return true;
        }
        if (this.headers[15].selectedCondition === 0) {
          return value.substring(0,9) === this.headers[15].conditionWords;
        }
        else if (this.headers[15].selectedCondition === 1) {
          return value.substring(0,6) === this.headers[15].conditionWords;
        }
        else if (this.headers[15].selectedCondition === 2) {
          let date: string[] = this.headers[15].conditionWords.split('-');
          return new Date(value) <= new Date(Number(date[0]), Number(date[1]) - 1, Number(date[2]), 23, 59, 59);
        }
        else if (this.headers[15].selectedCondition === 3) {
          let date: string[] = this.headers[15].conditionWords.split('-');
          return new Date(value) >= new Date(Number(date[0]), Number(date[1]) - 1, Number(date[2]), 23, 59, 59);
        }
      },
      items: ['に日付が一致 (YYYY-MM-DD)', 'に月が一致 (YYYY-MM)', '以前 (YYYY-MM-DD)', '以降 (YYYY-MM-DD)'],
      conditionWords: '',
      show: false,
      width: 250,
      sortable: true,
      selectedCondition: 0,
    },
    {
      text: 'Updated at',
      value: 'updated_at',
      filter: (value: string) => {
        if (!this.headers[16].show || !this.headers[16].conditionWords) {
          return true;
        }
        if (this.headers[16].selectedCondition === 0) {
          return value.substring(0,9) === this.headers[16].conditionWords;
        }
        else if (this.headers[16].selectedCondition === 1) {
          return value.substring(0,6) === this.headers[16].conditionWords;
        }
        else if (this.headers[16].selectedCondition === 2) {
          let date: string[] = this.headers[16].conditionWords.split('-');
          return new Date(value) <= new Date(Number(date[0]), Number(date[1]) - 1, Number(date[2]), 23, 59, 59);
        }
        else if (this.headers[16].selectedCondition === 3) {
          let date: string[] = this.headers[16].conditionWords.split('-');
          return new Date(value) >= new Date(Number(date[0]), Number(date[1]) - 1, Number(date[2]), 23, 59, 59);
        }
      },
      items: ['に日付が一致 (YYYY-MM-DD)', 'に月が一致 (YYYY-MM)', '以前 (YYYY-MM-DD)', '以降 (YYYY-MM-DD)'],
      conditionWords: '',
      show: false,
      width: 250,
      sortable: true,
      selectedCondition: 0,
    },
  ];
  private chosenColumnOptions: string[] = [
    'エクスポートする',
  ];
  private selected: [] = [];
  private rooms: { id: number, name: string, area: number, capacity: number, dayNormalPrice: string, dayCreditPrice: string, dayEarlyPrice: string, midNormalPrice: string, midCreditPrice: string, midEarlyPrice: string, studioImageUrl: string, material: string, vertical: number, width: number, created_at: string, updated_at: string }[] = [];
  private dialogFlg: boolean = false;
  private selectedImage: string = '';
  private search: string = '';
  private exportDialogFlg: boolean = false;
  private partialExportDialogFlg: boolean = false;
  private snackbar: { color: string, text: string, display: boolean, mode: string, x: string, y: string, timeout: number } = {
    color: '',
    text: '',
    display: false,
    mode: 'multi-line',
    x: 'right',
    y: 'top',
    timeout: 6000,
  };

  /**
   * 検索条件の文字列を返却する。
   */
  get showDefault() {
    return function(selectedCondition: number, index: number) {
      //@ts-ignore
      return this.headers[index].items[selectedCondition];
    };
  }

  created() {
    // スタジオ情報を取得し、画面に反映する
    // TODO: 店舗選択画面実装後、選択した店舗のIDが入るように修正
    this.clientAdapter.getAllStudioInfo().then((res: {id:number, name: string, area: number, capacity: number, dayNormalPrice: number, dayCreditPrice: number, dayEarlyPrice: number, MidNormalPrice: number, MidCreditPrice: number, MidEarlyPrice: number, store_id: number, studioImageUrl: string, material:string, vertical: number, width: number, created_at: string, updated_at: string}[]) => {
      res.forEach(room => {
        this.rooms.push(
            {
              id: room.id,
              name: room.name,
              area: room.area,
              capacity: room.capacity,
              studioImageUrl: room.studioImageUrl,
              dayNormalPrice: room.dayNormalPrice.toString(),
              dayCreditPrice: room.dayCreditPrice.toString(),
              dayEarlyPrice: room.dayEarlyPrice.toString(),
              midNormalPrice: room.MidNormalPrice.toString(),
              midCreditPrice: room.MidCreditPrice.toString(),
              midEarlyPrice: room.MidEarlyPrice.toString(),
              material: room.material,
              vertical: room.vertical,
              width: room.width,
              created_at: new Date(room.created_at).toLocaleString(),
              updated_at: new Date(room.updated_at).toLocaleString(),
            }
        );
      });
    });
  }

  /**
   * 検索条件を追加する
   * @param index
   * @private
   */
  private addCondition(index: number) {
    if (index === 0) {
      return;
    }
    this.headers[index].show = true;
  }

  /**
   * 検索条件を削除する
   * @param index
   * @private
   */
  private removeCondition(index: number) {
    this.headers[index].show = false;
    this.headers[index].conditionWords = '';
  }

  /**
   * 選択項目を...のリストを押下したときの処理
   * @param index
   * @private
   */
  private processChosenData(index: number) {
    if (this.selected.length === 0) {
      this.snackbar.display = true;
      this.snackbar.color = 'error';
      this.snackbar.text = '対象データを選択してください';
      return;
    }
    if (index === 0) {
      this.partialExportDialogFlg = true;
    }
  }

  /**
   * データエクスポートダイアログを表示する
   */
  private showExportDialog() {
    this.exportDialogFlg = true;
  }

  /**
   * 詳細ベージに遷移する
   * @param id
   * @private
   */
  private dataInfo(id: number) {
    //@ts-ignore
    this.$router.push({path: '/individualRoom', query: {id: id, tab: 0}});
  }

  /**
   * データ編集ページに遷移する
   * @param id
   * @private
   */
  private editData(id: number) {
    //@ts-ignore
    this.$router.push({path: '/individualRoom', query: {id: id, tab: 1}});
  }

  /**
   * データ削除ページに遷移する
   * @param id
   * @private
   */
  // private deleteData(id: number) {
  //   this.$router.push({path: '/individualRoom', query: {id: id}});
  // }

  private selectImage(image: string) {
    this.selectedImage = image;
  }

  /**
   * フィルター条件を変更する
   * @param index
   * @param i
   * @private
   */
  private changeFilterCondition(index: number, i: number) {
    this.headers[index].selectedCondition = i;
  }
}
