























































import {VueEx} from '@/common/core/VueEx';
import {Component} from 'vue-property-decorator';
import CouponTable from '@/views/coupon/CouponTable.vue';
import ExportCoupon from '@/views/coupon/ExportCoupon.vue';
import NewCoupon from '@/views/coupon/NewCoupon.vue';

@Component({
  components: {
    CouponTable,
    NewCoupon,
    ExportCoupon,
  },
})
export default class Coupon extends VueEx {
  private rsv_items: { text: string, disabled: boolean, href: string }[] = [
    {
      text: 'ダッシュボード',
      disabled: false,
      href: '/',
    },
    {
      text: 'Coupon',
      disabled: true,
      href: '/coupon',
    },
  ];
  private tab: number = 0;
  private items: { page: string, icon: string }[] = [
    {
      page: '一覧',
      icon: 'format-list-bulleted-square',
    },
    {
      page: '新規作成',
      icon: 'plus-thick',
    },
    // {
    //     page: 'エクスポート',
    //     icon: 'export',
    // },
  ];
}
