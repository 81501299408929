




















































import {VueEx} from '@/common/core/VueEx';
import {Component} from 'vue-property-decorator';
import ChargeListCsvDownload from "@/views/payjp/ChargeListCsvDownload.vue";
import CreaditCardSearch from "@/views/payjp/CreaditCardSearch.vue";

@Component({
  components: {
    CreaditCardSearch,
    ChargeListCsvDownload
  }
})
export default class Payjp extends VueEx {

  private rsv_items: { text: string, disabled: boolean, href: string }[] = [
    {
      text: 'ダッシュボード',
      disabled: false,
      href: '/',
    },
    {
      text: 'Payjp',
      disabled: true,
      href: '/payjp',
    },
  ];
  private tab: number = 0;
  private items: { page: string, icon: string }[] = [
    {
      page: '請求情報一覧ダウンロード',
      icon: 'tray-arrow-down',
    },
    {
      page: '登録カード情報取得',
      icon: 'credit-card-search-outline',
    },
  ];
}
