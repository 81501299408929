





































































// @ts-ignore
import {VueEx} from '@/common/core/VueEx';
import {Component} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class NewReservation extends VueEx {
  private userId: string = '';
  private headers: { text: string, value: string, width: number }[] = [
    {
      text: 'id',
      value: 'id',
      width: 100,
    },
    {
      text: 'exp_year',
      value: 'exp_year',
      width: 100,
    },
    {
      text: 'exp_month',
      value: 'exp_month',
      width: 100,
    },
    {
      text: 'last4h',
      value: 'last4',
      width: 100,
    },
    {
      text: 'brand',
      value: 'brand',
      width: 100,
    },
  ];
  private cards: {id: string, exp_year: string, exp_month: string, last4: string, brand: string}[] = [];

  private searchCreditCard() {
    this.cards = [];
    this.clientAdapter.getCreditCard(this.userId).then((res: {id: string, exp_year: string, exp_month: string, last4: string, brand: string}[]) => {
      res.forEach(card => {
        this.cards.push({
          id: card.id,
          exp_year: card.exp_year,
          exp_month: card.exp_month,
          last4: card.last4,
          brand: card.brand
        });
      })
    });
  }
}
