
























































import OptionTable from './option/OptionTable.vue';
import NewOption from './option/NewOption.vue';
import ExportOption from './option/ExportOption.vue';
import {VueEx} from '@/common/core/VueEx';
import {Component} from 'vue-property-decorator';

@Component({
  components: {
    OptionTable,
    NewOption,
    ExportOption,
  }
})
export default class Option extends VueEx {
  private rsv_items: { text: string, disabled: boolean, href: string }[] = [
    {
      text: 'ダッシュボード',
      disabled: false,
      href: '/',
    },
    {
      text: 'Option',
      disabled: true,
      href: '/option',
    },
  ];
  private tab: number = 0;
  private items: { page: string, icon: string }[] = [
    {
      page: '一覧',
      icon: 'format-list-bulleted-square',
    },
    {
      page: '新規作成',
      icon: 'plus-thick',
    },
    // {
    //   page: 'エクスポート',
    //   icon: 'export',
    // },
  ];
}
