
















































































































































































































































































import {VueEx} from '@/common/core/VueEx';
import {Component, Watch} from 'vue-property-decorator';
import ExportAttendance from '@/views/attendance/ExportAttendance.vue';
import AttendanceUserDetail from '@/views/attendance/individual/AttendanceUserDetail.vue';


@Component({
  components: {
    ExportAttendance,
    AttendanceUserDetail
  }
})
export default class AttendanceTable extends VueEx {
  private headers: { text: string, value: string, width: number }[] = [
    {
      text: 'Actions',
      value: 'actions',
      width: 125,
    },
    {
      text: 'Id',
      value: 'id',
      width: 70,
    },
    {
      text: 'Intime',
      value: 'intime',
      width: 200,
    },
    {
      text: 'Outtime',
      value: 'outtime',
      width: 200,
    },
    {
      text: 'MemberId',
      value: 'memberId',
      width: 120,
    },
    {
      text: 'UserName',
      value: 'userName',
      width: 120,
    },
    {
      text: 'UserTel',
      value: 'userTel',
      width: 120,
    },
    {
      text: 'UserEmail',
      value: 'userEmail',
      width: 250,
    },
    {
      text: 'PayjpCustomerId',
      value: 'payjpCustomerId',
      width: 250,
    },
    {
      text: 'StoreName',
      value: 'storeName',
      width: 120,
    },
    {
      text: 'BillingAmount',
      value: 'billingAmount',
      width: 150,
    },
    {
      text: 'IsCanceled',
      value: 'isCanceled',
      width: 120,
    },
    {
      text: 'RemoteLockKeyPin',
      value: 'remoteLockKeyPin',
      width: 170,
    },
    {
      text: 'PayJpBillingId',
      value: 'payJpBillingId',
      width: 150,
    },
    {
      text: 'Created at',
      value: 'created_at',
      width: 200,
    },
    {
      text: 'Updated at',
      value: 'updated_at',
      width: 200,
    },
  ];
  private attendances: { id: number, intime: string, outtime: string, memberId: number, userName: string, userTel: string, userEmail: string, payjpCustomerId: string, storeName: string, billingAmount: number, isCanceled: boolean, remoteLockKeyPin: number, payJpBillingId: string, created_at: string, updated_at: string }[] = [];
  private dialogFlg: boolean = false;
  private selected: { id: number, intime: string, outtime: string, memberId: number, userName: string, storeName: string, billingAmount: number, isCanceled: boolean, remoteLockKeyPin: number, payJpBillingId: string, created_at: string, updated_at: string }[] = [];
  private search: string = '';
  private exportDialogFlg: boolean = false;
  private partialExportDialogFlg: boolean = false;
  private chosenColumnOptions: string[] = [
    'エクスポートする',
    '削除する',
  ];
  snackbar: { color: string, text: string, display: boolean, mode: string, x: string, y: string, timeout: number } = {
    color: '',
    text: '',
    display: false,
    mode: 'multi-line',
    x: 'right',
    y: 'top',
    timeout: 6000,
  };
  private options: any = {};
  private showSearchCondition: boolean = false;
  private itemsPerPage: number = 0;
  // @ts-ignore
  private searchCondition: { memberId: number, dateFrom: string, dateTo: string, userName: string, userTel: string, userEmail: string, storeName: number, payjpCustomerId: string } = {};
  private loading: boolean = false;
  private loader = '';
  private menu1: boolean = false;
  private menu2: boolean = false;
  private totalCount: number = 0;
  private stores: { id: number, name: string }[] = [];
  private localStorageKeyForSearchCondition: string = 'attendanceSearchCondition';
  private userDetailDialog: boolean = false;
  private selectedUserId: string = "";

  @Watch('options')
  handler() {
    this.getAttendances();
  }

  created() {
    if (localStorage.getItem(this.localStorageKeyForSearchCondition)) {
      this.searchCondition = JSON.parse(localStorage.getItem(this.localStorageKeyForSearchCondition)!);
    }
    this.clientAdapter.getStoreInfo().then((res: { id: number, store_name: string }[]) => {
      this.stores.push({
        // @ts-ignore
        id: null,
        name: 'All',
      })
      res.forEach(store => {
        this.stores.push({
          id: store.id,
          name: store.store_name
        });
      });
    });
  }

  beforeDestroy() {
    // 検索条件を保存
    localStorage.setItem(this.localStorageKeyForSearchCondition, JSON.stringify(this.searchCondition));
  }

  handlePagination(newPagination: { itemsPerPage: number }) {
    this.itemsPerPage = newPagination.itemsPerPage;
  }

  /**
   * 選択項目を...のリストを押下したときの処理
   * @param index
   * @private
   */
  private processChosenData(index: number) {
    if (this.selected.length === 0) {
      this.snackbar.display = true;
      this.snackbar.color = 'error';
      this.snackbar.text = '対象データを選択してください';
      return;
    }
    if (index === 0) {
      this.partialExportDialogFlg = true;
    }
  }

  /**
   * データエクスポートダイアログを表示する
   */
  private showExportDialog() {
    this.exportDialogFlg = true;
  }

  /**
   * 詳細ベージに遷移する
   * @param id
   * @private
   */
  private dataInfo(id: number) {
    // @ts-ignore
    this.$router.push({path: '/individualAttendance', query: {id: id, tab: 0}});
  }

  /**
   * データ編集ページに遷移する
   * @param id
   * @private
   */
  private editData(id: number) {
    // @ts-ignore
    this.$router.push({path: '/individualAttendance', query: {id: id, tab: 1}});
  }

  /**
   * データ削除ページに遷移する
   * @param id
   * @private
   */
  private deleteData(id: number) {
    // @ts-ignore
    this.$router.push({path: '/individualAttendance', query: {id: id, tab: 2}});
  }

  private getAttendances() {
    this.loading = true;
    this.attendances = [];
    this.clientAdapter.getAttendances(this.options, this.searchCondition).then((res: { total: number, attendances: { id: number, intime: string, outtime: string, userId: number, userName: string, userTel: string, userEmail: string, payjpCustomerId: string, storeName: string, billing_amount: number, is_canceled: boolean, remote_lock_key_pin: number, pay_jp_billing_id: string, created_at: string, updated_at: string }[] }) => {
      res.attendances.forEach(attendance => {
        this.attendances.push({
          id: attendance.id,
          intime: new Date(attendance.intime).toLocaleString(),
          outtime: attendance.outtime == null ? '' : new Date(attendance.outtime).toLocaleString(),
          memberId: attendance.userId + 89,
          userName: attendance.userName,
          userTel: attendance.userTel,
          userEmail: attendance.userEmail,
          payjpCustomerId: attendance.payjpCustomerId,
          storeName: attendance.storeName,
          billingAmount: attendance.billing_amount,
          isCanceled: attendance.is_canceled,
          remoteLockKeyPin: attendance.remote_lock_key_pin,
          payJpBillingId: attendance.pay_jp_billing_id,
          created_at: new Date(attendance.created_at).toLocaleString(),
          updated_at: new Date(attendance.updated_at).toLocaleString(),
        });
      });
      this.totalCount = res.total;
      this.loading = false;
    });
  }

  private deleteSearchCondition() {
    //@ts-ignore
    this.searchCondition = {}
  }

  private showUserDetails(userId: number) {
      this.selectedUserId = String(userId);
      this.userDetailDialog = true;
  }
}
