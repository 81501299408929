











































































































































































































































































































































































































































































































































































































































































































































// @ts-ignore
import {Datetime} from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import {VueEx} from '@/common/core/VueEx';
import {Component, Watch} from 'vue-property-decorator';

interface Option {
  option_id: number;
  title: string;
  max_rental_amount: number;
  hint: string;
  amount: number;
  price: number;
}

interface categoryItem {
  categoryId: number;
  categoryTitle: string;
}

@Component({
  components: {
    datetime: Datetime,
  },
})
export default class NewReservation extends VueEx {
  private date: string = '';
  private startTime: string = '';
  private endTime: string = '';
  private midFlg: boolean = false;
  private isCommercialUse: boolean = false;
  private howToPay: string = '';
  private couponCode: string = '';
  private userId: string = '';
  private roomId: number = 0;
  private rooms: { id: number, store_id: number, name: string, dayNormalPrice: number, dayCreditPrice: number, dayEarlyPrice: number, MidNormalPrice: number, MidCreditPrice: number, MidEarlyPrice: number, onsite_pay_available: boolean }[] = [];
  private howToPays: { id: string, method: string }[] = [];
  private reserveStatuses: { id: string, status: string }[] = [];
  private userNumbers: number[] = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
    11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
  ];
  private parentUsages: { id: number, usage: string }[] = [];
  private childUsages: { id: number, usage: string, parentUsageId: number }[] = [];
  private filteredChildUsages: { id: number, usage: string, parentUsageId: number }[] = [];
  private selectedUserNumber: number = 0;
  private reserveStatus: string = '';
  private selectedParentUsageId: number = 0;
  private selectedChildUsageId: number = 0;
  private childUsageErrorMsg: string = '';
  private childUsageErrorFlg: boolean = false;
  private loader = '';
  private loading: boolean = false;
  private is_payment_skipped: boolean = false;
  private memo: string = '';
  private memoForAdmin: string = '';
  private reservationForm: boolean = false;
  private contentsConfirmationDialogFlg: boolean = false;
  // @ts-ignore
  private startDatetime: Date;
  // @ts-ignore
  private endDatetime: Date;
  private price: number = 0;
  private optionsPrice: number = 0;
  private totalPrice: number = 0;
  private parentUsage: string = '';
  private childUsage: string = '';
  private reservationForConfirmation: {} = {};
  private columnList: string[] = ['room', 'startDatetime', 'endDatetime', 'midFlg', 'isCommercialUse', 'earlyFlg', 'howToPay', 'totalPrice', 'studioPrice', 'optionsPrice', 'couponCode', 'parentUsage', 'childUsage', 'numberOfGuests', 'user', 'reserveStatus', 'selectedOptions', 'is_payment_skipped', 'memo', 'memoForAdmin'];
  private user_name: string = '';
  private filteredOptions: Option[] = [];
  private optionCards: { title: string, option_id: number, price: number, max_rental_amount: number, category_id: number }[] = [];
  private selectedCategory: string | null = null;
  private categoryItems: categoryItem[] = [];
  private selectedOption: Option | null = null;
  private selectedOptions: Option[] = [];
  private isOption: boolean = false;
  private convertedOptions: { id: number, amount: number, billing: number }[] = [];
  private snackbar: { color: string, text: string, display: boolean, mode: string, x: string, y: string, timeout: number } = {
    color: '',
    text: '',
    display: false,
    mode: 'multi-line',
    x: 'right',
    y: 'top',
    timeout: 6000,
  };
  private items: { title: string, placeholder: string, hint: string, rules: any }[] = [
    {
      title: 'date',
      placeholder: '',
      hint: '・必須',
      rules: {
        required: (value: string) => !value || '選択してください'
      },
    },
    {
      title: 'startTime',
      placeholder: '',
      hint: '・必須',
      rules: {
        required: (value: string) => !value || '選択してください'
      },
    },
    {
      title: 'endTime',
      placeholder: '',
      hint: '・必須',
      rules: {
        required: (value: string) => !value || '選択してください'
      },
    },
    {
      title: 'MidFlg',
      placeholder: '',
      hint: '・深夜予約の場合はチェックしてください',
      rules: null,
    },
    {
      title: 'isCommercialUse',
      placeholder: '',
      hint: '・商用利用の場合はチェックしてください',
      rules: null,
    },
    {
      title: 'HowToPay',
      placeholder: '1',
      hint: '・必須',
      rules: {
        required: (value: string) => !value || '選択してください'
      },
    },
    {
      title: 'NumberOfUsers',
      placeholder: '',
      hint: '・必須',
      rules: {
        required: (value: number) => value !== 0 || '選択してください'
      },
    },
    {
      title: 'Usage',
      placeholder: '',
      hint: '・必須',
      rules: null,
    },
    {
      title: 'UsageDetail',
      placeholder: '',
      hint: '・任意',
      rules: null,
    },
    {
      title: 'CouponCode',
      placeholder: '',
      hint: '・255文字以内',
      rules: {
        regex: (v: string) => {
          return v.length <= 255 || '255文字以内で入力してください。';
        }
      },
    },
    {
      title: 'UserId',
      placeholder: '',
      hint: '・必須',
      rules: {
        required: (value: number) => value !== 0 || '入力してください'
      },
    },
    {
      title: 'UserName',
      placeholder: '',
      hint: '',
      rules: null
    },
    {
      title: 'Room',
      placeholder: '',
      hint: '・必須',
      rules: {
        required: (value: string) => !value || '選択してください'
      },
    },
    {
      title: 'Option',
      placeholder: '',
      hint: '',
      rules: null,
    },
    {
      title: 'ReserveStatus',
      placeholder: '',
      hint: '・必須',
      rules: {
        required: (value: string) => !value || '選択してください'
      },
    },
    {
      title: 'PaymentSkipFlg',
      placeholder: '',
      hint: '',
      rules: null,
    },
    {
      title: 'Memo',
      placeholder: '',
      hint: '・memoの内容はユーザへ送信するメールに記載されます',
      rules: {
        regex: (v: string) => {
          return v.length <= 65535 || '65535文字以内で入力してください。';
        }
      },
    },
    {
      title: 'MemoForAdmin',
      placeholder: '',
      hint: '・memoForAdminの内容はユーザへ送信するメールには記載されません\n・スタッフ間の連絡にはこちらをご利用ください',
      rules: {
        regex: (v: string) => {
          return v.length <= 65535 || '65535文字以内で入力してください。';
        }
      },
    },
  ];

  get isEmptyUsageParent() {
    return this.selectedParentUsageId === 0;
  }

  private get isInvalid(): boolean {
    return !this.reservationForm || !this.howToPay || ((!this.startTime || !this.endTime) && !this.midFlg) || this.selectedUserNumber === 0 || this.roomId === 0 || this.userId === '';
  }

  created() {
    this.clientAdapter.getAllStudioInfo().then((res: { id: number, store_id: number, name: string, area: number, capacity: number, dayNormalPrice: number, dayCreditPrice: number, dayEarlyPrice: number, MidNormalPrice: number, MidCreditPrice: number, MidEarlyPrice: number, studioImageUrl: string, material: string, vertical: number, width: number, created_at: string, updated_at: string, onsite_pay_available: boolean }[]) => {
      res.forEach(room => {
        this.rooms.push({
          id: room.id,
          store_id: room.store_id,
          name: room.name,
          dayNormalPrice: room.dayNormalPrice,
          dayCreditPrice: room.dayCreditPrice,
          dayEarlyPrice: room.dayEarlyPrice,
          MidNormalPrice: room.MidNormalPrice,
          MidCreditPrice: room.MidCreditPrice,
          MidEarlyPrice: room.MidEarlyPrice,
          onsite_pay_available: room.onsite_pay_available,
        });
      });
    });
    // スタジオ用途を取得する
    this.clientAdapter.getUsages().then((res: { id: number, usage: string, parentFlg: boolean, parentUsageId: number }[]) => {
      res.forEach(usage => {
        if (usage.parentFlg) {
          this.parentUsages.push({
            id: usage.id,
            usage: usage.usage,
          });
        } else {
          this.childUsages.push({
            id: usage.id,
            usage: usage.usage,
            parentUsageId: usage.parentUsageId,
          });
        }
      });
      if (this.selectedParentUsageId !== 0) {
        this.filteredChildUsages = this.childUsages.filter(childUsage => childUsage.parentUsageId === this.selectedParentUsageId);
      }
    });
    Object.keys(this.config.reservation.howToPay).forEach(key => {
      this.howToPays.push({
        id: key,
        method: this.config.reservation.howToPay[key],
      });
    });
    Object.keys(this.config.reservation.reserveStatus).forEach(key => {
      this.reserveStatuses.push({
        id: key,
        status: this.config.reservation.reserveStatus[key],
      });
    });
    // オプションのカテゴリー情報取得
    this.clientAdapter.getOptionCategories().then((res: {
      id: number,
      category_id: number,
      name: string,
    }[]) => {
      res.forEach(category => {
        this.categoryItems.push({
          categoryId: category.category_id,
          categoryTitle: category.name,
        });
      });
    })
  }

  /**
   * 予約内容のバリデーションを行い、内容確認ダイアログを開く
   * @private
   */
  private openConfirmationDialog() {
    if (this.isInvalid) {
      this.showErrorDialog('必須項目が入力されていません。');
      return;
    }
    let room = this.rooms[0]; // 後続のエラー回避のため一旦rooms[0]を入れる
    this.rooms.forEach(r => {
      if (r.id === this.roomId) {
        room = r;
      }
    });
    let date = this.date.substring(0, 10);
    let startTime = this.startTime.substring(11, 16);
    let endTime = this.endTime.substring(11, 16);
    // 料金計算
    if (this.midFlg) { // 深夜の場合
      // 予約時間設定
      this.startDatetime = new Date(date + 'T' + '00:00:00');
      this.startDatetime.setDate(this.startDatetime.getDate() + 1);
      this.endDatetime = new Date(date + 'T' + '06:00:00');
      this.endDatetime.setDate(this.endDatetime.getDate() + 1);
      if (this.howToPay === '0') { // 現金料金
        this.price = room.MidNormalPrice;
      } else { // クレジット払い料金
        this.price = room.MidCreditPrice;
      }
    } else { // 深夜以外の場合
      // 予約時間設定
      this.startDatetime = new Date(date + 'T' + startTime + ':00');
      this.endDatetime = new Date(date + 'T' + endTime + ':00');
      if (endTime === '00:00') { // 終了時刻が24:00 (00:00) の場合、日付を1つ進める
        this.endDatetime.setDate(this.endDatetime.getDate() + 1);
      }
      // 終了時間と開始時間の差分計算
      let totalTime = (this.endDatetime.getTime() - this.startDatetime.getTime()) / (60 * 60 * 1000);
      if (this.howToPay === '0') {// 現金料金
        this.price = room.dayNormalPrice * totalTime * 2; // 30分単価 * 時間 * 2 (時間 * 2 で 30 分単価の計算に直している)
      } else { // クレジット払い料金
        this.price = room.dayCreditPrice * totalTime * 2; // 30分単価 * 時間 * 2 (時間 * 2 で 30 分単価の計算に直している)
      }
    }
    // 用途設定
    this.parentUsages.forEach(parent => {
      if (parent.id === this.selectedParentUsageId) {
        this.parentUsage = parent.usage;
      }
    });
    if (this.filteredChildUsages.length !== 0) {
      this.childUsages.forEach(child => {
        if (child.id === this.selectedChildUsageId) {
          this.childUsage = child.usage;
        }
      });
    }
    // 予約終了時間が予約開始時間より前、もしくは同時刻になっていないかチェック
    let start = this.midFlg ? 0 : Number(startTime.substring(0, 2));
    let end = this.midFlg ? 6 : Number(endTime.substring(0, 2));
    if (end !== 0 && (end < start || (!this.midFlg && startTime === endTime))) {
      // snackbarメッセージ表示
      this.showErrorDialog('終了時間が開始時間より前、もしくは同時刻になっています。');
      return;
    }
    // 予約時間が15:00〜20:30に被っていない、かつ現地決済を選択していた場合エラーにする
    if (!((15 <= start && start < 21) || (15 <= end && end < 21) || (start < 15 && 21 <= end)) && this.howToPay === '0') {
      // snackbarメッセージ表示
      this.showErrorDialog('現地決済不可な時間帯です。HowToPayを「事前決済」に変更してください。');
      return;
    }
    // 現地決済可否を確認する
    if (!room.onsite_pay_available && this.howToPay === '0') {
      this.showErrorDialog('現地決済不可なスタジオです。HowToPayを「事前決済」に変更してください。');
      return;
    }
    // selectedOptions配列からタイトルと個数を文字列に組み合わせる
    const optionDetails = this.selectedOptions.map(option => `${option.title} × ${option.amount}`);
    // 文字列をカンマで区切って結合
    const combinedOptionsString = optionDetails.join(', ');
    this.optionsPrice = this.selectedOptions.reduce((acc, option) => acc + (option.price * option.amount), 0);
    this.totalPrice = this.optionsPrice + this.price
    this.reservationForConfirmation = {
      room: room.name,
      startDatetime: this.startDatetime.toLocaleString(),
      endDatetime: this.endDatetime.toLocaleString(),
      midFlg: this.midFlg,
      isCommercialUse: this.isCommercialUse,
      earlyFlg: false,
      howToPay: this.howToPays[Number(this.howToPay)].method,
      totalPrice: this.totalPrice.toLocaleString(),
      studioPrice: this.price.toLocaleString(),
      optionsPrice: this.optionsPrice.toLocaleString(),
      couponCode: this.couponCode,
      parentUsage: this.parentUsage,
      childUsage: this.childUsage,
      numberOfGuests: this.selectedUserNumber,
      user: 'id: ' + this.userId + ' name: ' + this.user_name,
      selectedOptions: combinedOptionsString,
      reserveStatus: this.reserveStatuses[Number(this.reserveStatus)].status,
      is_payment_skipped: this.is_payment_skipped,
      memo: this.memo,
      memoForAdmin: this.memoForAdmin
    };
    this.contentsConfirmationDialogFlg = true;
  }

  private createReservation() {
    // 予約実行
    if (this.selectedOptions.length !== 0) {
      this.isOption = true
      this.convertedOptions = this.selectedOptions.map((option) => ({
        id: option.option_id,
        amount: option.amount,
        billing: option.price * option.amount
      }));
    }
    this.clientAdapter.reserveByAdmin(this.roomId, this.startDatetime.toString(), this.endDatetime.toString(), this.midFlg, false, Number(this.howToPay), this.totalPrice, this.price, this.couponCode, this.parentUsage, this.childUsage, this.selectedUserNumber, Number(this.userId), this.reserveStatus, this.is_payment_skipped, this.memo, this.memoForAdmin, this.isOption, this.price, this.optionsPrice, this.isCommercialUse, this.convertedOptions).then((res: { id: number }) => {
      localStorage.setItem('reservationId', res.id.toString());

      this.loader = '';
      // snackbarメッセージ表示
      this.snackbar.display = true;
      this.snackbar.color = 'success';
      this.snackbar.text = 'スタジオ予約に成功しました。';
      this.contentsConfirmationDialogFlg = false;
      // 項目クリア

    }).catch((err) => {
      // snackbarメッセージ表示
      this.showErrorDialog(err.data.message);
      this.contentsConfirmationDialogFlg = false;
    });

  }

  /**
   * 画面右上にエラーダイアログを表示する。
   * @param message
   * @private
   */
  private showErrorDialog(message: string) {
    // snackbarメッセージ表示
    this.snackbar.display = true;
    this.snackbar.color = 'error';
    this.snackbar.text = message;
    this.loader = '';
  }

  private filterChildUsages() {
    this.selectedChildUsageId = 0;
    this.filteredChildUsages = this.childUsages.filter(childUsage => childUsage.parentUsageId === this.selectedParentUsageId);
  }

  @Watch('loader')
  onLoaderChange() {
    this.loading = !this.loading;
  }

  private getUser() {
    if (this.userId) {
      this.clientAdapter.getUser(this.userId).then(res => {
        //@ts-ignore
        this.user_name = res.name;
      }).catch(() => {
        this.user_name = '';
      });
    }
  }

  private getOptionsByRoom(roomId: number) {
    const roomSelected = this.rooms.find((room) => room.id === roomId)
    const roomOptionRef = roomSelected?.store_id ?? 0;
    this.clientAdapter.getStoreOptionRefs(roomOptionRef).then((res: { id: number, store_id: number, option_id: number }[]) => {
      res.forEach(ref => {
        this.clientAdapter.getOptions(ref.option_id)
            .then((options: { id: number, option_id: number, name: string, price: number, unit: number, description: string, max_rental_amount: number, image: string, memo: string, category_id: number }) => {
              const OptionCard = {
                title: options.name,
                option_id: options.option_id,
                description: options.description,
                price: options.price,
                max_rental_amount: options.max_rental_amount,
                image: options.image,
                category_id: options.category_id,
              };
              this.optionCards.push(OptionCard);
            });
      });
    });
  }


  // max_rental_amountから数量の選択肢を生成する関数
  private getAmountItems(maxAmount: number): number[] {
    return Array.from(Array(maxAmount), (_, i) => i + 1);
  }


  private getCategoryById(categoryTitle: string) {
    const category = this.categoryItems.find(item => item.categoryTitle === categoryTitle);
    return category ? category.categoryId : ''; // null ではなく空の文字列を返す
  }

  private onRoomSelect(roomId: number) {
    if (this.optionCards.length !== 0) {
      this.optionCards = []
    }
    this.getOptionsByRoom(roomId)
    // カテゴリーとオプション選択クリア
    if (this.selectedCategory !== null) {
      this.clearSelections();
    }
  }

  private onCategorySelect() {
    const selectedCategoryTitle = this.selectedCategory;
    if (selectedCategoryTitle !== null) {
      const selectedCategoryId = this.getCategoryById(selectedCategoryTitle);
      const categoryIdAsNumber = selectedCategoryId !== '' ? Number(selectedCategoryId) : NaN;
      if (!isNaN(categoryIdAsNumber)) {
        this.filterOptionsByCategory(categoryIdAsNumber);
      }
    }
  }

  // カテゴリー選択時にフィルタリングされたオプションをセットするメソッド
  private filterOptionsByCategory(id: number) {
    if (this.selectedCategory !== null) {
      this.filteredOptions = this.optionCards
          .filter(option => option.category_id === id)
          .map(option => ({
            title: option.title,
            option_id: option.option_id,
            price: option.price,
            max_rental_amount: option.max_rental_amount,
            category_id: option.category_id,
            hint: "",
            amount: 0
          }));
    } else {
      this.filteredOptions = [];
    }
  }

  private onOptionSelect() {
    if (this.selectedOption !== null) {
      const selectedOptionId = this.selectedOption;
      // @ts-ignore
      const newSelectedOption = this.filteredOptions.find(option => option.option_id === selectedOptionId);
      if (newSelectedOption !== undefined) {
        this.selectedOption = newSelectedOption;
      } else {
        this.selectedOption = null;
      }
    }
  }

  private updateSelectedOptionAmount(amount: number) {
    if (this.selectedOption !== null) {
      this.selectedOption.amount = amount;
    }
  }

  private addSelectedOption() {
    if (this.selectedOption !== null) {
      const newOption = {...this.selectedOption};
      this.selectedOptions.push(newOption);
      // 他の処理（オプション合計計算など）を行うことができます
      this.clearSelections();
    }
  }

  private clearSelections() {
    this.selectedCategory = null;
    this.selectedOption = null;
  }

  private onChangeOption(
      item: { selectedNumber: number; title: string; price: number }, value: number): void {
    item.selectedNumber = value;
    // 該当するオプションのインデックスを取得する
    const optionIndex = this.selectedOptions.findIndex(option => option.title === item.title);
    if (optionIndex !== -1) {
      // selectedOptions を更新する
      this.$set(this.selectedOptions, optionIndex, {
        ...this.selectedOptions[optionIndex],
        number: item.selectedNumber,
        total_price: item.selectedNumber * item.price
      });
      // optionCards の該当するカードを更新する
      const cardIndex = this.optionCards.findIndex(card => card.title === item.title);
      if (cardIndex !== -1) {
        this.$set(this.optionCards, cardIndex, {
          ...this.optionCards[cardIndex],
          selectedNumber: item.selectedNumber
        });
      }
    }
  }

  private onDeleteOption(item: any): void {
    const deletedOption = item
    // selectedOptions から該当のオプションを削除する
    const selectCardIndex = this.selectedOptions.findIndex(card => card.title === deletedOption.title);
    if (selectCardIndex !== -1) {
      this.selectedOptions.splice(selectCardIndex, 1);
    }
    // optionCards の該当するカードを未選択の状態に戻す
    const cardIndex = this.optionCards.findIndex(card => card.title === deletedOption.title);
    if (cardIndex !== -1) {
      this.$set(this.optionCards, cardIndex, {
        ...this.optionCards[cardIndex],
        selectedNumber: undefined
      });
    }
  }
}
