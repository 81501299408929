


















































































































































































import {VueEx} from '@/common/core/VueEx';
import {Component} from 'vue-property-decorator';

@Component({})
export default class RoomImages extends VueEx {
  private search: string = '';
  private ct: string = '';
  private headers: { text: string, value: string, items: string[], show: boolean, width: number }[] = [
    {
      text: 'Id',
      value: 'id',
      items: ['数字…', '…から…', '------', '存在する', '空白'],
      show: false,
      width: 70,
    },
    {
      text: 'StudioId',
      value: 'studio_id',
      items: ['…', '含む', '完全に一致', 'で始まる', 'で終わる', '------', '存在する', '空白'],
      show: false,
      width: 100,
    },
    {
      text: 'RoomId',
      value: 'room_id',
      items: ['…', '含む', '完全に一致', 'で始まる', 'で終わる', '------', '存在する', '空白'],
      show: false,
      width: 100,
    },
    {
      text: 'Image',
      value: 'image',
      items: ['数字…', '…から…', '------', '存在する', '空白'],
      show: false,
      width: 150,
    },
    {
      text: 'ShowPriority',
      value: 'show_priority',
      items: ['数字…', '…から…', '------', '存在する', '空白'],
      show: false,
      width: 120,
    },
    {
      text: 'Created at',
      value: 'created_at',
      items: ['日付…', '…から…', '今日', '昨日', '今週', '先週', '------', '存在する', '空白'],
      show: false,
      width: 150,
    },
    {
      text: 'Updated at',
      value: 'updated_at',
      items: ['日付…', '…から…', '今日', '昨日', '今週', '先週', '------', '存在する', '空白'],
      show: false,
      width: 150,
    },
    {
      text: 'Actions',
      value: 'actions',
      items: [],
      show: false,
      width: 100,
    },
  ];
  private roomImages: { id: number, room_id: number, image: string, show_priority: number, created_at: string, updated_at: string }[] = [];
  private snackbar: { color: string, text: string, display: boolean, mode: string, x: string, y: string, timeout: number } = {
    color: '',
    text: '',
    display: false,
    mode: 'multi-line',
    x: 'right',
    y: 'top',
    timeout: 6000,
  };
  private dialogFlg: boolean = false;
  private selectedImage: string = '';

  created() {
    this.clientAdapter.getAllRoomImages().then((res: { id: number, rooms_id: number, imageUrl: string, show_priority: number, created_at: string, updated_at: string }[]) => {
      res.forEach(image => {
        this.roomImages.push({
          id: image.id,
          room_id: image.rooms_id,
          image: image.imageUrl,
          show_priority: image.show_priority,
          created_at: image.created_at,
          updated_at: image.updated_at,
        });
      });
    });
  }

  private selectImage(image: string){
    this.selectedImage = image;
  }

  private filterOnlyCapsText(value: any, search: any) {
    return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1;
  }

  private demoSuccess() {
    this.snackbar.display = true;
    this.snackbar.color = 'success';
    this.snackbar.text = 'Roomの作成に成功しました';
  }

  private demoError() {
    this.snackbar.display = true;
    this.snackbar.color = 'error';
    this.snackbar.text = 'Roomの作成に失敗しました';
  }

  private addCondition(index: number) {
    this.headers[index].show = true;
  }

  private removeCondition(index: number) {
    this.headers[index].show = false;
  }

  private dataInfo(studioName: string) {
    localStorage.setItem('studioName', studioName);
    this.$router.push('/indivisualRoom');
  }

  private editData(studioName: string) {
    localStorage.setItem('studioName', studioName);
    this.$router.push('/indivisualRoom');
  }

  private deleteData(studioName: string) {
    localStorage.setItem('studioName', studioName);
    this.$router.push('/indivisualRoom');
  }
}
