














































































































































































































































































import {VueEx} from '@/common/core/VueEx';
import {Component, Watch} from 'vue-property-decorator';
import ExportUser from '@/views/user/ExportUser.vue';

@Component({
  components: {
    ExportUser
  }
})
export default class UserTable extends VueEx {
  private search: string = '';
  private headers: { text: string, value: string, width: number }[] = [
    {
      text: 'Actions',
      value: 'actions',
      width: 100,
    },
    {
      text: 'Id',
      value: 'id',
      width: 70,
    },
    {
      text: 'MemberId',
      value: 'member_id',
      width: 110,
    },
    {
      text: 'Name',
      value: 'name',
      width: 300,
    },
    {
      text: 'Tel',
      value: 'tel',
      width: 150,
    },
    {
      text: 'Status',
      value: 'status',
      width: 200,
    },
    {
      text: 'Grade',
      value: 'grade',
      width: 90,
    },
    {
      text: 'AdminFlg',
      value: 'admin_flg',
      width: 110,
    },
    {
      text: 'Email',
      value: 'email',
      width: 110,
    },
    {
      text: 'Birthday',
      value: 'birthday',
      width: 110,
    },
    {
      text: 'Gender',
      value: 'gender',
      width: 110,
    },
    {
      text: 'Genre',
      value: 'genre',
      width: 110,
    },
    {
      text: 'LineId',
      value: 'line_id',
      width: 300,
    },
    {
      text: 'PayjpCustomerId',
      value: 'payjp_customer_id',
      width: 300,
    },
    {
      text: 'Memo',
      value: 'memo',
      width: 300,
    },
    {
      text: 'Created at',
      value: 'created_at',
      width: 200,
    },
    {
      text: 'Updated at',
      value: 'updated_at',
      width: 200,
    },
  ];

  private users: { id: number, name: string, member_id: number, tel: string, status: string, grade: number, admin_flg: boolean, email: string, birthday: string, gender: number, genre: string, memo: string, /** 本当は取りたいけど取れない last_sign_in_at: string, failed_attempts: number, */ line_id: string, payjp_customer_id: string, created_at: string, updated_at: string }[] = [];
  private chosenColumnOptions: string[] = [
    'エクスポートする',
  ];
  private snackbar: { color: string, text: string, display: boolean, mode: string, x: string, y: string, timeout: number } = {
    color: '',
    text: '',
    display: false,
    mode: 'multi-line',
    x: 'right',
    y: 'top',
    timeout: 6000,
  };
  private selected: { id: number, name: string, member_id: number, tel: string, status: string, grade: number, admin_flg: boolean, email: string, birthday: string, gender: number, /** 本当は取りたいけど取れない last_sign_in_at: string, failed_attempts: number, */ line_id: string, payjp_customer_id: string, created_at: string, updated_at: string }[] = [];
  private exportDialogFlg: boolean = false;
  private partialExportDialogFlg: boolean = false;
  private totalCount: number = 0;
  private loading: boolean = false;
  private options: any = {};
  private showSearchCondition: boolean = false;
  // @ts-ignore
  private searchCondition: { memberId: number, name: string, tel: string, status: number, grade: number, admin_flg: number, email: string, birthday: string, gender: number, genre: string } = {};
  private rooms: { id: number, name: string }[] = [];
  private loader = '';
  private menu1: boolean = false;
  private menu2: boolean = false;
  private localStorageKeyForSearchCondition: string = 'userSearchCondition';
  private statuses: { id: number, name: string }[] = [];
  private genders: { id: number, name: string }[] = [];
  private grades: { id: number, name: string }[] = [];
  private genres: string[] = [];
  private itemsPerPage: number = 0;
  // @ts-ignore
  private adminFlgs: { id: number, name: string }[] = [{id: null, name: ''}, {id: 0, name: 'false'}, {id: 1, name: 'true'}];

  @Watch('options')
  handler() {
    this.getUsers();
  }

  created() {
    if (localStorage.getItem(this.localStorageKeyForSearchCondition)) {
      this.searchCondition = JSON.parse(localStorage.getItem(this.localStorageKeyForSearchCondition)!);
    }
    Object.keys(this.config.user.status).forEach(key => {
      this.statuses.push({
        // @ts-ignore
        id: null,
        name: '',
      })
      this.statuses.push({
        id: Number(key),
        name: this.config.user.status[key],
      });
    });
    Object.keys(this.config.user.gender).forEach(key => {
      this.genders.push({
        // @ts-ignore
        id: null,
        name: '',
      })
      this.genders.push({
        id: Number(key),
        name: this.config.user.gender[key],
      });
    });
    Object.keys(this.config.user.grade).forEach(key => {
      this.grades.push({
        // @ts-ignore
        id: null,
        name: '',
      })
      this.grades.push({
        id: Number(key),
        name: this.config.user.grade[key],
      });
    });
    this.genres = this.config.user.genre;
    this.genres.unshift('');
  }

  beforeDestroy() {
    // 検索条件を保存
    localStorage.setItem(this.localStorageKeyForSearchCondition, JSON.stringify(this.searchCondition));
  }

  handlePagination(newPagination: { itemsPerPage: number }) {
    this.itemsPerPage = newPagination.itemsPerPage;
  }

  /**
   * 選択項目を...のリストを押下したときの処理
   * @param index
   * @private
   */
  private processChosenData(index: number) {
    if (this.selected.length === 0) {
      this.snackbar.display = true;
      this.snackbar.color = 'error';
      this.snackbar.text = '対象データを選択してください';
      return;
    }
    if (index === 0) {
      this.partialExportDialogFlg = true;
    }
  }

  private exportUsers() {
    this.snackbar.display = true;
    this.snackbar.color = 'success';
    this.snackbar.text = 'Userをエクスポートしました';
  }

  /**
   * データエクスポートダイアログを表示する
   */
  private showExportDialog() {
    this.exportDialogFlg = true;
  }

  private dataInfo(email: string, index: number) {
    console.log(this.users[index].status);
    let withdrawalFlg = this.users[index].status === '退会済み';
    //@ts-ignore
    this.$router.push({path: '/individualUser', query: {email: email, tab: 0, withdrawalFlg: withdrawalFlg}});
  }

  private editData(email: string, index: number) {
    let withdrawalFlg = this.users[index].status === '退会済み';
    //@ts-ignore
    this.$router.push({path: '/individualUser', query: {email: email, tab: 1, withdrawalFlg: withdrawalFlg}});
  }

  private deleteData(email: string) {
    //@ts-ignore
    this.$router.push({path: '/individualUser', query: {email: email, tab: 2}});
  }

  private getUsers() {
    this.loading = true;
    this.users = [];
    this.clientAdapter.getUsers(this.options, this.searchCondition).then((res: { total: number, users: { id: number, name: string, member_id: number, tel: string, status: number, grade: number, admin_flg: boolean, email: string, birthday: string, gender: number, genre: string, memo: string, /** 本当は取りたいけど取れない last_sign_in_at: string, failed_attempts: number, */line_id: string, payjp_customer_id: string, created_at: string, updated_at: string }[] }) => {
      res.users.forEach(user => {
        this.users.push({
          id: user.id,
          name: user.name,
          member_id: user.id + 89,
          tel: user.tel,
          status: this.config.user.status[user.status],
          grade: this.config.user.grade[user.grade],
          admin_flg: user.admin_flg,
          email: user.email,
          birthday: user.birthday,
          gender: this.config.user.gender[user.gender],
          genre: user.genre,
          line_id: user.line_id,
          payjp_customer_id: user.payjp_customer_id,
          memo: user.memo,
          created_at: new Date(user.created_at).toLocaleString(),
          updated_at: new Date(user.updated_at).toLocaleString(),
        });
      });
      this.totalCount = res.total;
      this.loading = false;
    });
  }

  private deleteSearchCondition() {
    //@ts-ignore
    this.searchCondition = {}
  }
}
