












































































import {VueEx} from '@/common/core/VueEx';
import {Component, Watch} from 'vue-property-decorator';

@Component({})
export default class DeleteReservation extends VueEx {
  private columns: string[] = [
    'ReserveStartTime',
    'ReserveEndTime',
    'Billing',
    'UserName',
    'RoomName',
  ];
  private detail: string[] = [];
  private id: any = this.$route.query.id;
  private snackbar: { color: string, text: string, display: boolean, mode: string, x: string, y: string, timeout: number } = {
    color: '',
    text: '',
    display: false,
    mode: 'multi-line',
    x: 'right',
    y: 'top',
    timeout: 6000,
  };
  private message: string = '';
  private cancelType: number = 0;
  private cancelReason: string = '';
  private cancelReasonNotice: { hint: string, rules: any } = {
    hint: '100文字以内で、スペースを含めずに入力してください。',
    rules: {
      regex: (v: string) => {
        return v.length <= 100 || '100文字以内で入力してください。';
      }
    },
  };
  private cancelReasonForm: boolean = true;
  private cancelPrice: number = 0;
  private loader = '';
  private loading: boolean = false;

  @Watch('loader')
  onLoaderChange() {
    this.loading = !this.loading;
  }

  private get canCancel(): boolean {
    return this.cancelReasonForm;
  }

  created() {
    this.clientAdapter.getReservation(Number(this.$route.query.id))
        .then((res: {
          id: number,
          createTime: string,
          reserveStartTime: string,
          reserveEndTime: string,
          midFlg: boolean,
          earlyFlg: boolean,
          howToPay: number,
          billing: number,
          studioBilling: number,
          optionBilling: number,
          paymentFlg: boolean,
          cancelDate: string,
          cancelType: number,
          couponCode: string,
          userName: string,
          memberId: number,
          roomName: string,
          created_at: string,
          updated_at: string,
          highLevelUsage: string,
          detailUsage: string,
          reserveStatus: number,
          numberOfUsers: number,
        }) => {
          this.detail = [
            new Date(res.reserveStartTime).toLocaleString(),
            new Date(res.reserveEndTime).toLocaleString(),
            res.billing.toLocaleString() + '円',
            res.userName,
            res.roomName,
          ];
          // 予約リクエスト状態の場合、キャンセル料は発生しない
          if (res.reserveStatus === 0) {
            this.cancelPrice = 0;
            this.message = '※キャンセル料金は発生しません。';
            this.cancelType = 3;
            return;
          }
          let fullCommissionDate = new Date();
          fullCommissionDate.setDate(fullCommissionDate.getDate() + 2);
          fullCommissionDate.setHours(0);
          fullCommissionDate.setMinutes(1);
          let halfCommissionDate = new Date();
          halfCommissionDate.setDate(halfCommissionDate.getDate() + 7);
          halfCommissionDate.setHours(0);
          halfCommissionDate.setMinutes(1);
          let reserveDate = new Date(res.reserveStartTime);
          if (res.reserveStatus === 0) {
            this.cancelPrice = 0;
            this.message = '※キャンセル料金は発生しません。';
            this.cancelType = 1;
          }
          if (reserveDate.getTime() < fullCommissionDate.getTime()) {
            this.cancelPrice = res.billing;
            this.message = '※キャンセル料金が' + this.cancelPrice.toLocaleString() + '円発生します。';
            this.cancelType = 1;
          } else if (reserveDate.getTime() < halfCommissionDate.getTime()) {
            this.cancelPrice = Math.round(res.billing * 0.5);
            this.message = '※キャンセル料金が' + this.cancelPrice.toLocaleString() + '円発生します。';
            this.cancelType = 2;
          } else {
            this.cancelPrice = 0;
            this.message = '※キャンセル料金は発生しません。';
            this.cancelType = 3;
          }
        });
  }

  /**
   * 予約をキャンセルする
   * @private
   */
  private cancel() {
    this.clientAdapter.cancelReservation(this.id, this.cancelType, this.cancelPrice, this.cancelReason).then(() => {
      // snackbarメッセージ表示
      this.showSnackbar('予約のキャンセルに成功しました。', 'success');
    }).catch((err) => {
      // snackbarメッセージ表示
      this.showSnackbar(err.message, 'error');
    });
  }

  /**
   * 画面右上にsnackbarを表示する。
   * @param message
   * @private
   */
  private showSnackbar(message: string, type: string) {
    // snackbarメッセージ表示
    this.snackbar.display = true;
    this.snackbar.color = type;
    this.snackbar.text = message;
    this.loader = '';
  }
}
